import {createBrowserHistory} from "history";
import links from "../constants/links";

const history = createBrowserHistory();
export let nowPage;
history.listen((location) => {
  nowPage = _setNowRoute(location);
})

export function defaultRedirect() {
  const { dashboard } = links.pages;
  if (history.location.pathname === '/') {
    history.push(dashboard.path);
    nowPage = dashboard;
  } else {
    nowPage = _setNowRoute(history.location);
  }
}

function _setNowRoute (location) {
  return Object.values(links.pages).find(page => page.isNowRoute(location));
}
export default history;
