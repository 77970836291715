import React from 'react';
import './styles/settings-page.scss';

const SettingsPage = (props) => {
  const { children } = props;

  return (
    <div className="settings-page settings-page_background cf">
      { children }
    </div>
  )
}

export default SettingsPage;