import React from 'react';
import './styles/dashboard.scss';
import SettingsPage from "../SettingsPage/SettingsPage";
import HeaderSettingPage from "../SettingsPage/components/HeaderSettingPage/HeaderSettingPage";
import ContentBodySettingPage from "../SettingsPage/components/ContentBodySettingPage/ContentBodySettingPage";
import ContentAreaSettingPage from "../SettingsPage/components/ContentAreaSettingPage/ContentAreaSettingPage";
import ContentAreaHeader
  from "../SettingsPage/components/ContentAreaSettingPage/components/ContentAreaHeader/ContentAreaHeader";
import ContentAreaBody
  from "../SettingsPage/components/ContentAreaSettingPage/components/ContentAreaBody/ContentAreaBody";
import FilterList from "../FilterList/FilterList";
import Statistics from "../Statistics/Statistics";
import CompanySettings from "../CompanySettings/CompanySettings";
import {useStore} from "../../store";
import TestStore from "../TestStore/TestStore";
import {AppEnvList} from "../../constants/AppEnvList";
import {useTranslation} from "react-i18next";
import Dialog from "../Dialog/Dialog";
import UserGuideEn from "../Dialog/UserGuide/UserGuideEn";
import UserGuideIt from "../Dialog/UserGuide/UserGuideIt";

const Dashboard = () => {
  const {
    ecwidStore: {
      storeInfo
    }
  } = useStore();

  const { t } = useTranslation('dashboard');

  return (
    <SettingsPage>
      <Dialog
        title={t('user_guide.title')}
      >
        {storeInfo && storeInfo.lang === 'it' ? <UserGuideIt/> : <UserGuideEn/>}
      </Dialog>
      <HeaderSettingPage
        title={t('title')}
      />
      <ContentBodySettingPage>
        <ContentAreaSettingPage>
          <ContentAreaHeader
            title={t("balance.header")}
            description={t("balance.description")}
          />
          <ContentAreaBody>
            {storeInfo && <div className="a-card a-card--normal">
              <div className="a-card__paddings">
                <div className="statistic-grid statistic-grid--row-count-2 statistic-grid--col-count-3">
                  <span className="statistic-grid__title">{t("balance.bought_balance")}</span>
                  <span className="statistic-grid__value">{ storeInfo.bought_clicks }</span>
                  <span className="statistic-grid__title">{t("balance.spent_balance")}</span>
                  <span className="statistic-grid__value">{ storeInfo.spent_clicks }</span>
                  <span className="statistic-grid__title">{t("balance.remaining_balance")}</span>
                  <span className="statistic-grid__value">{ storeInfo.bought_clicks - storeInfo.spent_clicks }</span>
                </div>
              </div>
            </div>}
          </ContentAreaBody>
        </ContentAreaSettingPage>
        <ContentAreaSettingPage>
          <ContentAreaHeader
            title={t('campaign_management.header')}
            description={t('campaign_management.description')}
          />
          <ContentAreaBody>
            {storeInfo && <CompanySettings
              balance={storeInfo.bought_clicks - storeInfo.spent_clicks}
            />}
            {process.env.REACT_APP_APP_ENV !== AppEnvList.prod && <TestStore/>}
          </ContentAreaBody>
        </ContentAreaSettingPage>
        <ContentAreaSettingPage>
          <ContentAreaHeader
            title={t('product_listing.header')}
            description={t('product_listing.description')}
          />
          <ContentAreaBody>
            <div className="a-card a-card--normal">
              <div className="a-card__paddings">
                <FilterList/>
              </div>
            </div>
          </ContentAreaBody>
        </ContentAreaSettingPage>
        <ContentAreaSettingPage>
          <ContentAreaHeader
            title={t('statistics.header')}
            description={t('statistics.description')}
          />
          <ContentAreaBody>
            <div className="a-card a-card--normal">
              <div className="a-card__paddings">
                <Statistics/>
              </div>
            </div>
          </ContentAreaBody>
        </ContentAreaSettingPage>
      </ContentBodySettingPage>
    </SettingsPage>
  )
}

export default Dashboard;