const links = {
  pages: {
    dashboard: {
      path: '/dashboard',
      name: 'dashboard',
    },
    somethingWentWrong: {
      path: '/something-went-wrong',
    },
    notFound: {
      path: '/not-found',
    }
  }
};

function isNowRoute(location) {
  const pathname = location?.pathname || location;
  if (this.pathRegular) {
    return this.pathRegular.test(pathname);
  } else {
    return this.path === pathname || `${this.path}/` === pathname;
  }
}

Object.keys(links.pages).forEach(link => {
  Object.defineProperty(links.pages[link], 'isNowRoute', {
    value: isNowRoute,
  })
})

export default links;