import React from 'react';
import SomethingWentWrong from "../../components/SomethingWentWrong/SomethingWentWrong";
import './styles/somethingWentWrongPage.scss';

function SomethingWentWrongPage () {
    return (
      <div className="somethingWentWrongPageWrapper">
        <SomethingWentWrong/>
      </div>
    )
}

export default SomethingWentWrongPage;
