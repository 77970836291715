import balanceClicks from './assets/it/№1.png';
import fillBrand from './assets/it/№2.png';
import fillUPC from './assets/it/№3.png';
import fillMPN from './assets/it/№4.png';
import fillOutMPN from './assets/it/№5.png';
import addProductTypes from './assets/it/№6.png';
import newProductType from './assets/it/№7.png';
import genderAgeColorSizeFill from './assets/it/№8.png';
import changeType from './assets/it/№9.png';
import genderAgeColorSizeFillOut from './assets/it/№10.png';
import madeInYearFill from './assets/it/№11.png';
import attributeMadeInYear from './assets/it/№12.png';
import kelkooCampaignManagement from './assets/it/№13.png';
import productListingManagement from './assets/it/№14.png';
import statistics from './assets/it/№15.png';

export default function UserGuideIt() {
    return (
        <>
            <div className="user-guide-step">
                <h3>1. Per iniziare</h3>
                <p>
                    Aumenta le vendite e attira nuovi clienti verso il tuo negozio on-line pubblicando i tuoi prodotti su Kelkoo. Questa funzionalità integra il comparatore di prezzo Kelkoo nel tuo Ecommerce: tu acquisti click e Kelkoo pubblica i tuoi prodotti nella sua grande rete commerciale; ogni volta che un utente clicca sul tuo prodotto su Kelkoo, il click è scalato dal budget residuo a tua disposizione.
                </p>

                <span className="text-big">Con questa funzionalità potrai gestire facilmente campagne ed elenchi di prodotti su Kelkoo. Potrai creare e gestire campagne, così come raccogliere statistiche:</span>
                <ol>
                    <li>Selezionare i prodotti da pubblicare.</li>
                    <li>Monitorare lo stato dei click e il rapporto tra click acquistati, ricevuti e residui.</li>
                    <li>Schedulare la campagna e scegliere uno o più intervalli di tempo.</li>
                    <li>Raccogliere statistiche su click e ordini.</li>
                </ol>
            </div>
            <div className="user-guide-step">
                <h3>2. Attivazione dell’account Kelkoo del merchant</h3>
                <p>
                    Per abilitare l’account Kelkoo, contatta il tuo riferimento IOL e richiedi di acquistare i tuoi primi click per poter poi far partire la tua campagna di attivazione.
                </p>
            </div>
            <div className="user-guide-step">
                <h3>3. Sezione Situazione Click</h3>
                <span className="text-big">Questa sezione mostra le numeriche dei click, più precisamente:</span>
                <ol>
                    <li>‘Numero click totali’ mostra la somma dei click acquistati fin dalla prima attivazione su Kelkoo.</li>
                    <li>‘Click ricevuti’ è la somma di tutti i click spesi, ossia quante volte gli utenti hanno cliccato sui tuoi prodotti su Kelkoo.</li>
                    <li>‘Click residui’ mostra la differenza tra i click acquistati e i click ricevuti, e sono quelli ancora utilizzabili per la campagna in corso.</li>
                </ol>

                <p>
                    <span className="text-big">Importante:</span> la campagna sarà automaticamente sospesa quando si esauriscono i click residui. La situazione click è aggiornata ogni 24 ore.
                </p>

                <div className="image">
                    <figure>
                        <img src={balanceClicks} alt="balance clicks"/>
                    </figure>
                </div>
            </div>
            <div className="user-guide-step">
                <h3>4. Prima di attivare una campagna: attivare i prodotti</h3>
                <p>
                    Ci sono diversi attributi di prodotto necessari per la pubblicazione su Kelkoo. I prodotti potrebbero non essere pubblicati in mancanza di essi.
                </p>
                <h4>Marca</h4>
                <p>
                    Attributo richiesto per prodotti con marchio o produttore specifico. Se i prodotti sono di produzione propria (fatti a mano) o non hanno un marchio specifico, questo attributo può essere omesso o sostituito con “Produzione Artigianale” o simili, che permetterà al prodotto di essere indicizzato come tale.
                </p>
                <span className="text-big">Per aggiungere l'attributo Marca a un prodotto:</span>
                <ol>
                    <li>Dal tuo back office vai su Catalogo → Prodotti.</li>
                    <li>Apri il prodotto che desideri modificare.</li>
                    <li>Vai alla scheda Attributi.</li>
                    <li>
                        Compila il campo Marchio:
                    </li>
                    <div className="image">
                        <img src={fillBrand} alt="fill brand"/>
                    </div>
                    <li>Clicca su Salva per confermare la modifica.</li>
                </ol>

                <h4>GTIN o codice unico</h4>
                <p>
                    GTIN (Global Trade Item Number) è un identificatore unico di prodotto, come l’EAN o l’ISBN. Si tratta della versione digitale del codice a barre.
                </p>
                <p>
                    Per aggiungere il GTIN (UPS) a un prodotto, apri gli Attributi di un prodotto e compila il campo UPC (codice universale di prodotto):
                </p>
                <div className="image">
                    <img src={fillUPC} alt="fill UPC"/>
                </div>

                <h4>MPN</h4>
                <p>
                    L’MPN (Manufacturer Part Number) è richiesto per tutti i prodotti che non hanno assegnato un GTIN. L’MPN può essere una stringa alfanumerica, quindi in mancanza di un MPN si può anche utilizzare la SKU del prodotto.
                </p>
                
                <p>Per aggiungere l’attributo MPN al tuo prodotto, dovrai innanzitutto creare un attributo personalizzato:</p>
                <ol>
                    <li>Dal tuo back office vai in Impostazioni → Tipi di Prodotto.</li>
                    <li>Digita MPN nel campo testo di un attributo personalizzato:</li>
                    <div className="image">
                        <img src={fillMPN} alt="fill MPN"/>
                    </div>
                    <li>Clicca su Salva per salvare le modifiche.</li>
                </ol>

                <p>Ora puoi aggiungere un valore per l’MPN del tuo prodotto:</p>
                <ol>
                    <li>Dal tuo back office vai in Catalogo -> Prodotti.</li>
                    <li>Apri il prodotto che vuoi modificare.</li>
                    <li>Vai nel tab Attributi.</li>
                    <li>Compila il campo MPN:</li>
                    <div className="image">
                        <img src={fillOutMPN} alt="fill out MPN"/>
                    </div>
                    <li>Clicca su Salva per salvare le modifiche.</li>
                </ol>

                <h4>Genere, colore, fascia d’età</h4>
                <p>
                    Attributi come genere, colore e fascia d’età sono richiesti per i prodotti che ricadono sotto la categoria Abbigliamento & Accessori poiché aiutano i clienti a filtrare per colore, età e sesso su Google Shopping.
                </p>
                <p>
                    Per assegnare questi attributi ai tuoi prodotti, devi prima creare un tipo di prodotto Abbigliamento e Accessori:
                </p>

                <ol>
                    <li>Dal tuo back office vai in Impostazioni → Tipi di Prodotto.</li>
                    <li>Clicca Nuovo tipo di prodotto:</li>
                    <div className="image">
                        <img src={addProductTypes} alt="add product type"/>
                    </div>
                    <li>Espandi l'albero delle categorie o utilizza la buca di ricerca per trovare il tipo adatto al tuo prodotto:</li>
                    <div className="image">
                        <img src={newProductType} alt="new product type"/>
                    </div>
                    <li>Clicca su Aggiungi. Verrà visualizzato un elenco di attributi richiesti per il tipo di prodotto impostato:</li>
                    <div className="image">
                        <img src={genderAgeColorSizeFill} alt="gender age color size fill"/>
                    </div>
                </ol>

                <p>Per compilare i valori degli attributi:</p>
                <ol>
                    <li>Dal tuo back office vai su Catalogo → Prodotti.</li>
                    <li>Apri il prodotto che desideri modificare.</li>
                    <li>Vai al tab Attributi.</li>
                    <li>Clicca Cambia Tipo e seleziona il nuovo tipo di prodotto:</li>
                    <div className="image">
                        <img src={changeType} alt="change type"/>
                    </div>
                    <li>Compila i campi degli attributi con i valori appropriati:</li>
                    <div className="image">
                        <img src={genderAgeColorSizeFillOut} alt="gender age color size fill out"/>
                    </div>
                    <li>Clicca su Salva per salvare le modifiche.</li>
                </ol>

                <h4>Prodotto in, Anno</h4>
                <p>
                    Gli attributi Prodotto in e Anno sono obbligatori per i prodotti della categoria Vini e Liquori, poiché aiutano i clienti a scegliere i prodotti per località e anno di produzione.
                </p>
                <p>Per aggiungere Prodotto in e Anno ai tuoi prodotti, devi prima creare un attributo come questo:</p>
                <ol>
                    <li>Dal tuo back office vai in Impostazioni → Tipi di Prodotto.</li>
                    <li>Aggiungi una casella di testo e inserisci il nome dell'attributo Prodotto in e Anno al suo interno:</li>
                    <div className="image">
                        <img src={madeInYearFill} alt="madeIn year fill"/>
                    </div>
                    <li>Clicca su Salva per salvare le modifiche.</li>
                </ol>
                <p>Adesso puoi aggiungere questi due attributi ai tuoi prodotti:</p>
                <ol>
                    <li>Dal tuo back office vai su Catalogo → Prodotti.</li>
                    <li>Apri il prodotto che desideri modificare.</li>
                    <li>Vai al tab Attributi.</li>
                    <li>Compila i campi Prodotto in e Anno:</li>
                    <div className="image">
                        <img src={attributeMadeInYear} alt="attribute madeIn year"/>
                    </div>
                    <li>Clicca su Salva per salvare le modifiche.</li>
                </ol>

                <h4>Altri attributi</h4>
                <p>
                    Kelkoo offre altri attributi che possono essere assegnati ai prodotti:
                </p>
                <ul>
                    <li>Materiale: tessuto o materiale di cui è fatto il prodotto (esempi: cotone, lana, seta, ecc..)</li>
                    <li>Green Label: alle etichette che mostrano i tuoi prodotti è stata concessa una certificazione ecologica (esempi: EU-Eco-regulation, NF Environment, FSC, Fair Trade, ecc..)</li>
                    <li>Classe di efficienza energetica — Etichetta energetica del prodotto (Valori supportati: A+++, A++, A+, A, B, C, D, E, F, G)</li>
                    <li>Regione vinicola (solo per alcolici e vino) — La regione di produzione (esempi: Alsazia, Dry Creek Valley, Friuli-Venezia Giulia, Linguadoca-Rossiglione, Piemonte, Veneto)</li>
                    <li>Numero bottiglie vino (solo per alcolici e vino) — Il numero di bottiglie nella scatola o confezione</li>
                    <li>Capacità del vino (solo per alcolici e vino) — La capacità della bottiglia (esempio: 0,75 l)</li>
                    <li>Aderenza sul bagnato deei pneumatici (solo per pneumatici, eccetto quando richiesto dalle leggi e dai regolamenti locali) — Classificazione di aderenza sul bagnato degli pneumatici (valori supportati: A, B, C, D, E, F, G)</li>
                    <li>Classe di rumorosità dei pneumatici (solo per pneumatici — eccetto quando richiesto dalle leggi e dai regolamenti locali) — Classificazione della classe di rumorosità per i pneumatici (valori supportati: A, B, C)</li>
                    <li>Formato (per musica, film e libri) — I tipi di file (formati audio: CD, Vinile, Boxset, Streaming; Formati video: Blu-ray, DVD, Streaming; Esempi per libri: eBook, Copertina rigida, brossure; ecc..)</li>
                    <li>Genere (per musica, film e libri) — Lo stile di musica, film o libri (Generi musicali: Blues, Hip-hop, Country, Electro; Generi di film: Azione, Animazione, Bollywood; Esempi di libri: Biografia, Bambini, Narrativa; ecc..)</li>
                </ul>
                <p>
                    È possibile trovare l'elenco completo dei parametri e degli attributi di Kelkoo&nbsp;
                    <a rel="noreferrer"
                       target="_blank"
                       href="https://developers.kelkoogroup.com/app/documentation/navigate/_merchant/merchantProductData/_/_/ProductDataSpecs">
                        qui
                    </a>
                </p>
            </div>
            <div className="user-guide-step">
                <h3>5. Gestione campagne Kelkoo</h3>
                <p>In questa sezione puoi gestire lo stato delle campagne Kelkoo. È possibile attivare o sospendere la tua campagna. La campagna verrà sospesa automaticamente se non ci sono più click residui o se il saldo dei click è negativo. Puoi disattivare le campagne in qualsiasi momento modificando lo stato in "Non Attivo".</p>
                <p>Per impostazione predefinita, la campagna è attiva quando il suo stato è "Attivo". Puoi anche creare una pianificazione della campagna. Per farlo, attiva "Schedula campagna". Seleziona una o più intervalli temporali. La campagna verrà messa in pausa se la data corrente non rientra in uno degli intervalli selezionati.</p>
                <p>Imposta le date di inizio e fine per ogni intervallo. Le date vengono visualizzate secondo il fuso orario settato nel back office del tuo negozio. Puoi modificare la data di inizio di ogni slot in qualsiasi momento a partire dalla data corrente, e modificare la data di fine per estendere la tua campagna o terminarla prima.</p>
                <p>
                    <span className="text-big">Importante:</span> le modifiche allo stato non verranno applicate immediatamente. Il sistema monitora e aggiorna le modifiche diverse volte al giorno.
                </p>
                <div className="image">
                    <figure>
                        <img src={kelkooCampaignManagement} alt="kelkoo campaign management"/>
                    </figure>
                </div>
            </div>
            <div className="user-guide-step">
                <h3>6. Sezione Gestione elenco prodotti</h3>
                <p>In questa sezione puoi selezionare i prodotti da caricare su Kelkoo.
                    Tutti i prodotti del tuo negozio Ecwid sono visualizzati qui (vedi fig. 3).
                </p>
                <ul className="list-none">
                    <li>1 - Nome del prodotto</li>
                    <li>2 - Codice SKU</li>
                    <li>3 - Stato del prodotto (indipendentemente dal fatto che sia visibile ai clienti o meno)</li>
                    <li>4 - Disponibilità in stock, numero di prodotti disponibili in stock</li>
                    <li>5, 6 - Puoi filtrare e cercare per nome prodotto, proprio come nel negozio Ecwid</li>
                    <li>7 - Stato dell'elenco Kelkoo. Se è abilitato, il prodotto verrà caricato su Kelkoo</li>
                    <li>8, 9 - È possibile selezionare più prodotti e aggiornarne in massa lo stato.</li>
                </ul>
                <p>
                    <span className="text-big">Importante:</span> lo stato dell'elenco Kelkoo si aggiorna più volte al giorno (7:00, 11:00, 15:00, 23:00 UTC). Le modifiche (quantità, nome, SKU, attributi, ecc.) verranno applicate se sono state apportate prima di uno degli orari di aggiornamento.
                </p>
                <div className="image">
                    <figure>
                        <img src={productListingManagement} alt="product listing management"/>
                    </figure>
                </div>
            </div>
            <div className="user-guide-step">
                <h3>7. Sezione Statistiche</h3>
                <p>In questa sezione vengono visualizzate le statistiche della campagna: numero di clic ricevuti su Kelkoo e numero di ordini in negozio. Scegli un intervallo di tempo per il quale desideri visualizzare le statistiche: ultimi 7 giorni, ultimi 30 giorni, ultimo anno, tutto.</p>
                <div className="image">
                    <img src={statistics} alt="Statistics"/>
                </div>
            </div>
        </>
    );
}