import React from 'react';

const ContentAreaHeader = (props) => {
  const { title, description } = props;

  return (
    <div className="named-area__header">
      <div className="named-area__titles">
        <div className="named-area__title">
          { title }
        </div>
      </div>
      <div className="named-area__description" dangerouslySetInnerHTML={
        {__html: description}
      }>
      </div>
    </div>
  )
}

export default ContentAreaHeader;