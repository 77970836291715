import ProductsStore from "./ProductsStore";
import StatisticsStore from "./StatisticsStore";
import EcwidStore from "./EcwidStore";
import ModalStore from "./ModalStore";

class RootStore {
    constructor(context, initialValues) {
        this.i18n = context.i18n;
        this.api = context.api;

        this.ecwidStore = new EcwidStore(context);
        this.productStore = new ProductsStore(context);
        this.statisticsStore = new StatisticsStore(context);
        this.modalStore = new ModalStore();

        this._getStore.bind(this);
    }

    _onChangeLanguage = (lng) => {
        this.ecwidStore.setLocale(lng);
    };

    changeLanguage = (lng) => {
        this.i18n.changeLanguage(lng);
    };

    _getStore = (storeName) => {
        return this[storeName];
    }
}

export default RootStore;
