import React from "react";
import notProductsImage from './assets/not-products.svg'
import './styles/empty-products-list.scss';
import i18n from "i18next";

export default class EmptyProductsList extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.myRef.current.parentNode.classList.remove('long-list');
  }

  componentWillUnmount() {
    this.myRef.current.parentNode.classList.add('long-list');
  }

  render() {
    return (
      <div
        className="empty-products-list d-flex flex-column align-items-center"
        ref={this.myRef}
      >
        <img src={notProductsImage}
             height="173"
             width="270"
             alt={'Нет товаров'}/>
        <div className="text-default">
          {i18n.t('product_listing.filters.not_found_products.start')}
          <span
            className="info cursor-pointer"
            onClick={this.props.setDefaultFilterSetting}
          >
            &nbsp;{i18n.t('product_listing.filters.not_found_products.end')}
          </span>
        </div>
      </div>
    )
  }
}