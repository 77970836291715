// eslint-disable-next-line import/no-anonymous-default-export
export default {
    set: function (name, value) {
        localStorage.setItem(name, value);
    },
    get: function (name) {
        return localStorage.getItem(name);
    },
    remove: function (name) {
        localStorage.removeItem(name);
    }
};
