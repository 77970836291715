import EcwidInfoEndpoint from './EcwidInfoEndpoint';
import ProductsEndpoint from "./ProductsEndpoint";
import StatisticsEndpoint from "./StatisticsEndpoint";

// eslint-disable-next-line
export default [
  {name: 'ecwidInfo', endpoint: EcwidInfoEndpoint},
  {name: 'products', endpoint: ProductsEndpoint},
  {name: 'statistics', endpoint: StatisticsEndpoint},
];
