import React from "react";
import './styles/list-intervals.scss';

import ListIntervalsItem from "./ListIntervalsItem/ListIntervalsItem";
import i18n from "i18next";

export default function ListIntervals(props) {
    const {onRemove, onCheck, intervals} = props;

    return (
        <div className="list-intervals">
            <div className="collapsible collapsible--opened">
                <div className="collapsible__header d-flex justify-content-between"
                     onClick={(event) => {
                         event.target.parentNode.classList.toggle('collapsible--opened');
                     }}>
                    {i18n.t('campaign_management.interval.time_slots')}
                </div>
                <div className="collapsible__body">
                    {intervals && intervals?.map(interval => (
                        <ListIntervalsItem
                            key={interval.id}
                            intervals={intervals}
                            interval={interval}
                            onRemove={() => onRemove(interval.id)}
                            onCheck={(interval) => onCheck(interval)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}