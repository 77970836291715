import React from 'react';
import './styles/content-body-setting-page.scss';

const ContentBodySettingPage = (props) => {
  const { children } = props;

  return (
    <div className="settings-page__body">
      { children }
    </div>
  )
}

export default ContentBodySettingPage;