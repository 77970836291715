import apiList from "../../constants/apiList";

// eslint-disable-next-line
export default class {
  constructor(axios) {
    this.axios = axios;
  };

  async getCategories() {
    try {
      const response = await this.axios({
        method: 'get',
        url: apiList.ecwidCategories,
      })
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async getProducts({keyword, category, inStock, enabled, page, isActive}) {
    try {
      const filter = {};

      if (keyword) {
        filter.keyword = keyword;
      }
      if (category !== 'all') {
        filter.category = +category;
      }
      if (inStock !== 'all' && inStock) {
        filter.inStock = inStock === 'on';
      }
      if (enabled !== 'all' && enabled) {
        filter.enabled = enabled === 'on';
      }
      if (!page) {
        filter.page = 1;
      } else {
        filter.page = page;
      }

      if (isActive === "true") {
        filter.is_active = 1
      } else {
        filter.is_active = 0
      }

      const response = await this.axios({
        method: 'get',
        url: apiList.ecwidProducts,
        params: filter
      })
      return response.data.data;
    } catch (error) {
      throw error
    }
  };

  async setProductStatus(ecwidProductId, status) {
    try {
      const response = await this.axios({
        method: 'post',
        url: apiList.ecwidSetProductStatus(ecwidProductId),
        params: {
          status
        }
      })
      return response.data.data;
    } catch (error) {
      throw error
    }
  }

  async setStatusesProducts(ecwidProductsIds, status) {
    try {
      const response = await this.axios({
        method: 'post',
        url: apiList.ecwidSetProductsStatuses,
        params: {
          productsIds: JSON.stringify(ecwidProductsIds),
          status: status ? 1 : 0
        }
      })
      return response.data.data;
    } catch (error) {
      throw error
    }
  }
}