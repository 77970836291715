import React, {forwardRef} from "react";
import './styles/company-settings.scss';
import DatePicker, {registerLocale} from "react-datepicker";
import {StoreContext} from "../../store";
import cx from "classnames";
import moment from "moment";
import i18n from "i18next";
import en from "date-fns/locale/en-GB";
import { v4 as uuidv4 } from 'uuid';
import ListIntervals from "./components/ListIntervals/ListIntervals";
import {_periodInclude, _periodOverlaps} from "./components/ListIntervals/utils/IntervalsService";

registerLocale('en-GB', en);

export default class CompanySettings extends React.Component {
  i18n;

  state = {
    storeInfo: null,
    company: {
      status: false,
      title: '',
    },
    interval: {
      status: false,
      startDate: null,
      endDate: null,
      error: {
        status: false,
        message: ''
      }
    },
    intervals: [],
    defaultIntervalStatus: null,
    defaultCompany: null,
    defaultIntervals: [],
    hasChanges: false,
    error: {
      status: false,
      className: '',
      text: '',
      description: ''
    }
  }

  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);

    const {
      ecwidStore: {
        storeInfo
      },
    } = this.context;

    this.setState(() => ({
      storeInfo: storeInfo
    }));

    this.setState((state) => ({
      interval: {
        ...state.interval,
        status: state.storeInfo.status_interval
      },
      intervals: state.storeInfo.campaign_intervals ? JSON.parse(state.storeInfo.campaign_intervals) : []
    }));

    this.setStatus(storeInfo.campaign_management, false);

    this.setState((state) => ({
      defaultCompany: state.company,
      defaultIntervalStatus: state.interval.status,
      defaultIntervals: state.intervals
    }));

    if (this.props.balance <= 0) {
      this.setState((state) => ({
        error: {
          status: true,
          className: 'a-card--warning',
          text: i18n.t('campaign_management.warning.header'),
          description: i18n.t('campaign_management.warning.description')
        }
      }));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.interval.status) {
      this._initInput(document.getElementById('startDateIntervalCompany'));
      this._initInput(document.getElementById('endDateIntervalCompany'));
    }
  }

  _initInput(input) {
    if (input) {
      if (!input.value) {
        input.parentNode.classList.remove('field--filled');
      }
      input.addEventListener('focus', function () {
        this.parentNode.classList.add('field--focus');
      });
      input.addEventListener('blur', function () {
        this.parentNode.classList.remove('field--focus');
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.wrapperRef.current.querySelector('.list-dropdown').classList.remove('d-block');
    }
  }

  setStatus(status, check = true) {
    if (status) {
      this.setState((state) => ({
        company: {
          ...state.company,
          title: i18n.getResource(i18n.language, 'dashboard', 'campaign_management.started'),
          status: true
        }
      }), () => {
        if (check) {
          this._checkWithDefault();
        }
      })
    } else if (!status) {
      this.setState((state) => ({
        company: {
          ...state.company,
          title: i18n.getResource(i18n.language, 'dashboard', 'campaign_management.stopped'),
          status: false
        }
      }), () => {
        if (check) {
          this._checkWithDefault();
        }
      })
    }
  }

  handleSetChecked(status) {
    this.setState((state) => ({
      interval: {
        ...state.interval,
        status: status
      }
    }), () => {
      this._checkWithDefault();
    });
  }

  setStartDate = (date) => {
    this.setState((state) => ({
      interval: {
        ...state.interval,
        startDate: date
      }
    }), () => {
      const startDate = document.getElementById('startDateIntervalCompany');
      if (startDate.value) {
        startDate.parentNode.classList.remove('field--filled');
      } else {
        startDate.parentNode.classList.add('field--filled');
      }
      this._checkWithDefault();
    });
  }

  clearStartDate() {
    this.setState((state) => ({
      interval: {
        ...state.interval,
        startDate: null
      }
    }), () => {
      this._checkWithDefault();
    });
  }

  setEndDate = (date) => {
    this.setState((state) => ({
      interval: {
        ...state.interval,
        endDate: date
      }
    }), () => {
      const endDate = document.getElementById('endDateIntervalCompany');
      if (endDate.value) {
        endDate.parentNode.classList.remove('field--filled');
      } else {
        endDate.parentNode.classList.add('field--filled');
      }
      this._checkWithDefault();
    });
  }

  clearEndDate() {
    this.setState((state) => ({
      interval: {
        ...state.interval,
        endDate: null
      }
    }), () => {
      this._checkWithDefault();
    });
  }

  startCompany(event) {
    if (this.props.balance <= 0) {
      this._setErrorMessage();

      return;
    }

    this.setStatus(true);
    this._hideDropDown(event);
  }

  stopCompany(event) {
    if (this.props.balance <= 0) {
      this._setErrorMessage();

      return;
    }

    this.setStatus(false);
    this._hideDropDown(event);
  }

  cancelChanges() {
    this.setState((state) => ({
      interval: {
        ...state.interval,
        status: state.defaultIntervalStatus
      },
      intervals: [
        ...state.defaultIntervals
      ],
      company: {
        ...state.defaultCompany
      }
    }), () => {
      this._checkWithDefault();
    });
  }

  savedSettings() {
    const {company, interval, intervals} = this.state;

    const {
      ecwidStore: {
        changeStatusCompany,
        getStoreInfo
      },
    } = this.context;

    const params = {
      campaign_management: company.status ? 1 : 0,
      campaign_schedule: interval.status ? 1 : 0,
      intervals: interval.status ? intervals.length ? JSON.stringify(intervals) : null : null
    }

    changeStatusCompany(params).then(() => {
      console.log('Статус кампании изменен');
      getStoreInfo().then(response => {
        this.context.ecwidStore.setStoreInfo(response);

        this.setState(() => ({
          storeInfo: response
        }));

        this.setStatus(response.campaign_management, false);

        this.setState((state) => ({
          defaultCompany: state.company,
          defaultIntervalStatus: state.interval.status,
          defaultIntervals: state.intervals
        }), () => {
          this.setState((state) => ({
            interval: {
              ...state.interval,
              error: {
                status: false,
                message: ''
              }
            }
          }));
          this._checkWithDefault();
        });
      });
    });
  }

  addInterval() {
    const { interval, intervals } = this.state;

    if (!interval.startDate || !interval.endDate) {
      this.setState((state) => ({
        interval: {
          ...state.interval,
          error: {
            status: true,
            message: i18n.t('campaign_management.interval.intervals_required')
          }
        }
      }));

      return;
    } else if (_periodOverlaps(interval, intervals)) {
      this.setState((state) => ({
        interval: {
          ...state.interval,
          error: {
            status: true,
            message: i18n.t('campaign_management.interval.intervals_period_overlaps')
          }
        }
      }));

      return;
    } else {
      this.setState((state) => ({
        interval: {
          ...state.interval,
          error: {
            status: false,
            message: ''
          }
        }
      }));
    }

    this.setState((state) => ({
      intervals: [...state.intervals, {
        id: uuidv4(),
        startDate: moment.utc(state.interval.startDate),
        endDate: moment.utc(state.interval.endDate)
      }].sort((a, b) => a.startDate - b.startDate)
    }), () => {
      this.setState((state) => ({
        interval: {
          ...state.interval,
          startDate: null,
          endDate: null
        }
      }));

      this._checkWithDefault();
    });
  }

  removeInterval(id) {
    this.setState((state) => ({
      intervals: [
        ...state.intervals.filter(i => i.id !== id)
      ]
    }), () => {
      this._checkWithDefault();
    });
  }

  onCheckInterval(interval) {
    this.setState((state) => ({
      intervals: [
        ...state.intervals
            .map(i => i.id === interval.id ? interval : i)
            .sort((a, b) => a.startDate - b.startDate)
      ]
    }), () => {
      this._checkWithDefault();
    });
  }

  _checkWithDefault() {
    const defaultCompanyJSON = JSON.stringify(this.state.defaultCompany);
    const defaultIntervalsJSON = JSON.stringify(this.state.defaultIntervals);
    const defaultIntervalStatusJSON = JSON.stringify(this.state.defaultIntervalStatus);

    const companyJSON = JSON.stringify(this.state.company);
    const intervalsJSON = JSON.stringify(this.state.intervals);
    const intervalStatusJSON = JSON.stringify(this.state.interval.status);

    this.setState((state) => ({
      hasChanges: defaultCompanyJSON !== companyJSON ||
          defaultIntervalsJSON !== intervalsJSON ||
          defaultIntervalStatusJSON !== intervalStatusJSON
    }));
  }

  _hideDropDown(event) {
    event.target.closest('.list-dropdown').classList.remove('d-block');
  }

  _setErrorMessage() {
    this.setState(() => ({
      error: {
        status: true,
        className: 'a-card--error',
        text: i18n.t('campaign_management.error.header'),
        description: i18n.t('campaign_management.error.description')
      }
    }))
  }

  _currentStatus() {
    const campaignStatus = _periodInclude(moment.utc(new Date()), this.state.defaultIntervals);
    const currentStatus = this.state.storeInfo.current_status;
    const nextStatus = this.state.storeInfo.next_status;

    if (this.state.defaultIntervals.length === 0) {
      return i18n.t('campaign_management.interval.campaign_phase_active');
    } else if (!this.state.interval.status) {
      return i18n.t('campaign_management.interval.campaign_phase_active');
    } else if (campaignStatus !== -1) {

      if (currentStatus) {
        const startDate = moment(currentStatus.startDate).format('DD.MM.YYYY');
        const endDate = moment(currentStatus.endDate).format('DD.MM.YYYY');

        return `${i18n.t('campaign_management.interval.campaign_phase_active')} (${startDate} - ${endDate})`;
      }

      return i18n.t('campaign_management.interval.campaign_phase_active');
    } else {
      if (nextStatus) {
        const startDate = moment(nextStatus.startDate).format('DD.MM.YYYY');
        const endDate = moment(nextStatus.endDate).format('DD.MM.YYYY');

        return `${i18n.t('campaign_management.interval.campaign_phase_paused')} (${i18n.t('campaign_management.interval.next_interval')} ${startDate} - ${endDate})`;
      }

      return i18n.t('campaign_management.interval.campaign_phase_paused');
    }
  }

  render() {
    const {company, interval, error, hasChanges} = this.state;

    const {balance} = this.props;

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const StartDateInput = forwardRef(({value, onClick, onChange}, ref) => (
      <div className="fieldset fieldset--with-label"
           style={
             {
               width: '120px'
             }
           }
      >
        <div className={cx("field field--large", {"field--filled": value})}
             onClick={onClick}>
          <span className="fieldset__svg-icon"/>
          <label className="field__label">{i18n.t('campaign_management.interval.start_date')}</label>
          <input type="text"
                 id="startDateIntervalCompany"
                 className="field__input"
                 ref={ref} value={value}
                 readOnly
                 onChange={onChange}
          />
          <div className="field__placeholder">{i18n.t('campaign_management.interval.start_date')}</div>
          <span className="field-state--success">
                  <svg xmlns="http://www.w3.org/2000/svg" width="26px"
                       height="26px" viewBox="0 0 26 26" focusable="false">
                  <path
                    d="M5 12l5.02 4.9L21.15 4c.65-.66 1.71-.66 2.36 0 .65.67.65 1.74 0 2.4l-12.3 14.1c-.33.33-.76.5-1.18.5-.43 0-.86-.17-1.18-.5l-6.21-6.1c-.65-.66-.65-1.74 0-2.41.65-.65 1.71-.65 2.36.01z"/></svg>
              </span>
          <span className="field-state--close">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 16 16" enableBackground="new 0 0 16 16"
                     xmlSpace="preserve" focusable="false">
                  <path
                    d="M15.6,15.5c-0.53,0.53-1.38,0.53-1.91,0L8.05,9.87L2.31,15.6c-0.53,0.53-1.38,0.53-1.91,0c-0.53-0.53-0.53-1.38,0-1.9l5.65-5.64L0.4,2.4c-0.53-0.53-0.53-1.38,0-1.91c0.53-0.53,1.38-0.53,1.91,0l5.64,5.63l5.74-5.73c0.53-0.53,1.38-0.53,1.91,0c0.53,0.53,0.53,1.38,0,1.91L9.94,7.94l5.66,5.65C16.12,14.12,16.12,14.97,15.6,15.5z"/></svg>
              </span>
        </div>
        <div className="field__error" aria-hidden="true" style={{display: 'none'}}/>
      </div>
    ));

    const EndDateInput = forwardRef(({value, onClick, onChange}, ref) => (
      <div className="fieldset fieldset--with-label"
           onClick={onClick}
           style={{width: '120px'}}>
        <div className={cx("field field--large", {"field--filled": value})}>
          <span className="fieldset__svg-icon"/>
          <label className="field__label">{i18n.t('campaign_management.interval.end_date')}</label>
          <input type="text"
                 id="endDateIntervalCompany"
                 className="field__input"
                 ref={ref}
                 readOnly
                 value={value}
                 onChange={onChange}
          />
          <div className="field__placeholder">{i18n.t('campaign_management.interval.end_date')}</div>
          <span className="field-state--success">
                  <svg xmlns="http://www.w3.org/2000/svg" width="26px"
                       height="26px" viewBox="0 0 26 26" focusable="false">
                  <path
                    d="M5 12l5.02 4.9L21.15 4c.65-.66 1.71-.66 2.36 0 .65.67.65 1.74 0 2.4l-12.3 14.1c-.33.33-.76.5-1.18.5-.43 0-.86-.17-1.18-.5l-6.21-6.1c-.65-.66-.65-1.74 0-2.41.65-.65 1.71-.65 2.36.01z"/></svg>
              </span>
          <span className="field-state--close">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 16 16" enableBackground="new 0 0 16 16"
                     xmlSpace="preserve" focusable="false">
                  <path
                    d="M15.6,15.5c-0.53,0.53-1.38,0.53-1.91,0L8.05,9.87L2.31,15.6c-0.53,0.53-1.38,0.53-1.91,0c-0.53-0.53-0.53-1.38,0-1.9l5.65-5.64L0.4,2.4c-0.53-0.53-0.53-1.38,0-1.91c0.53-0.53,1.38-0.53,1.91,0l5.64,5.63l5.74-5.73c0.53-0.53,1.38-0.53,1.91,0c0.53,0.53,0.53,1.38,0,1.91L9.94,7.94l5.66,5.65C16.12,14.12,16.12,14.97,15.6,15.5z"/></svg>
              </span>
        </div>
        <div className="field__error" aria-hidden="true" style={{display: 'none'}}/>
      </div>
    ));

    return (
      <div>
        <div className={cx(
          'a-card a-card--normal',
          {[error.className]: balance <= 0},
          {'a-card--info background-info': hasChanges}
        )} id="company-settings">
          <div className="a-card__paddings a-card__paddings--default-background">
            <div className={cx(
              'iconable-block iconable-block--hide-in-mobile',
              {'iconable-block--disabled': !company.status}
            )}>
              <div className="iconable-block__content">
                <div
                  className={cx('status-block', {'status-block--disabled': !company.status})}>
                  <div className="status-block__central">
                    <div className="status-block__header">
                      <span className="status-block__title">{i18n.t('campaign_management.campaign')}</span>
                    </div>
                    <div className="status-block__content">
                      <div className="d-flex align-items-center mt-2">
                        <div className="inline-alert inline-alert--success text-big mt-0 mr-2">{i18n.t('campaign_management.status')}:</div>
                        <div className="dropdown-menu text-default" ref={this.wrapperRef}>
                          <div className="dropdown-menu__link">
                            <a href="#link" className="iconable-link"
                               onClick={(event) => {
                                 event.preventDefault();
                                 event.target.closest('.dropdown-menu').querySelector('.list-dropdown').classList.toggle('d-block');
                               }}
                            >
                              <span className="iconable-link__text text-big">{company.title}</span>
                              <span className="iconable-link__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 28"
                                 focusable="false">
                              <path
                                d="M3.3 9.5l5.6 5.1 6-5.1c.8-.7 1.9-.7 2.6 0 .8.7.8 1.8 0 2.5l-7.2 6.4c-.5.4-1 .6-1.4.6s-1-.2-1.3-.5L.7 12.1c-.8-.7-.8-1.8 0-2.5.6-.8 1.9-.8 2.6-.1z"/></svg>
                          </span>
                            </a>
                          </div>
                          <div className="list-dropdown list-dropdown-medium d-none" style={{width: '210px'}}>
                            <ul className="list-dropdown__item--company">
                              <li onClick={(event) => this.startCompany(event)}>{i18n.t('campaign_management.campaign_started')}</li>
                              <li onClick={(event) => this.stopCompany(event)}>{i18n.t('campaign_management.campaign_stopped')}</li>
                            </ul>
                          </div>
                          <select className="dropdown-menu__list text-default" aria-hidden="true"
                                  style={{display: 'none'}}/></div>
                      </div>
                      <div className="company-status_description text-small" dangerouslySetInnerHTML={
                        {__html: i18n.t('campaign_management.status_description')}
                      }>
                      </div>

                      {company.status && <>
                        <div className="d-flex justify-content-between flex-wrap company-status-settings-interval">
                          <div className="d-flex flex-column">
                            <h4 className={cx({'disabled-color': balance <= 0})}>{i18n.t('campaign_management.interval.header')}</h4>
                            <div className="company-status-settings-interval_description text-small" dangerouslySetInnerHTML={
                              {__html: i18n.t('campaign_management.interval.description')}
                            }>
                            </div>
                          </div>
                          <div className="status-block__primary-action">
                            <label className="checkbox big">
                              <input
                                  type="checkbox"
                                  disabled={balance <= 0}
                                  onChange={(event) => this.handleSetChecked(event.target.checked)}
                                  checked={interval.status}/>
                              <div>
                                <div/>
                              </div>
                            </label>
                          </div>
                        </div>

                        {interval.status && balance > 0 && <div className="d-flex flex-column">
                          <div className="d-flex mt-2">
                            <DatePicker
                                customInput={<StartDateInput/>}
                                dateFormat="dd.MM.yyyy"
                                locale="en-GB"
                                selected={interval.startDate}
                                minDate={moment().toDate()}
                                maxDate={moment(interval.endDate).subtract(1, 'day').toDate()}
                                onChange={(date) => this.setStartDate(date)}
                                shouldCloseOnSelect={false}
                                renderCustomHeader={({
                                                       date,
                                                       changeYear,
                                                       changeMonth,
                                                       decreaseMonth,
                                                       increaseMonth,
                                                       prevMonthButtonDisabled,
                                                       nextMonthButtonDisabled,
                                                     }) => (
                                    <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          margin: "20px 16px 8px 16px",
                                          fontSize: "12px",
                                          lineHeight: "18px",
                                          fontWeight: "600"
                                        }}
                                    >
                                      {months[date.getMonth()]} {date.getFullYear()}
                                      <div className="d-flex justify-content-between align-items-center">
                                        <button className="months-button cursor-pointer" onClick={decreaseMonth}
                                                disabled={prevMonthButtonDisabled}>
                                          <svg width="6" height="18" viewBox="0 0 6 18" fill="none"
                                               xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M5.72243 12.221L2.98651 9.08207L5.72243 5.78052C6.09094 5.37408 6.09094 4.71127 5.72243 4.30483C5.34833 3.89839 4.75089 3.89839 4.38238 4.30483L0.976441 8.33797C0.792185 8.53806 0.697266 8.80694 0.697266 9.07582C0.697266 9.34469 0.792185 9.61357 0.976441 9.81366L4.38238 13.6967C4.75089 14.1032 5.35391 14.1032 5.72801 13.6967C6.09094 13.2903 6.09094 12.6275 5.72243 12.221Z"
                                                  fill={prevMonthButtonDisabled ? '#ABB5C0' : '#068EEF'}
                                            />
                                          </svg>
                                        </button>
                                        <button className="months-button cursor-pointer" onClick={increaseMonth}
                                                disabled={nextMonthButtonDisabled}>
                                          <svg width="6" height="18" viewBox="0 0 6 18" fill="none"
                                               xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M0.277575 12.221L3.01349 9.08207L0.277575 5.78052C-0.0909371 5.37408 -0.0909371 4.71127 0.277575 4.30483C0.65167 3.89839 1.24911 3.89839 1.61762 4.30483L5.02356 8.33797C5.20781 8.53806 5.30273 8.80694 5.30273 9.07582C5.30273 9.34469 5.20781 9.61357 5.02356 9.81366L1.61762 13.6967C1.24911 14.1032 0.646086 14.1032 0.271991 13.6967C-0.0909376 13.2903 -0.0909371 12.6275 0.277575 12.221Z"
                                                  fill={nextMonthButtonDisabled ? '#ABB5C0' : '#068EEF'}
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                )}
                            >
                              <div className="react-datepicker_button_clear">
                                <div className="react-datepicker_button_clear_wrapper">
                                  <button className="btn btn-link" onClick={() => this.clearStartDate()}>Clear</button>
                                </div>
                              </div>
                            </DatePicker>
                            <DatePicker
                                customInput={<EndDateInput/>}
                                dateFormat="dd.MM.yyyy"
                                locale="en-GB"
                                selected={interval.endDate}
                                minDate={
                                  interval.startDate ?
                                      moment().toDate() > moment(interval.startDate).toDate() ?
                                          moment().add(1, 'day').toDate() : moment(interval.startDate).add(1, 'day').toDate() :
                                      moment().add(1, 'day').toDate()
                                }
                                onChange={(date) => this.setEndDate(date)}
                                shouldCloseOnSelect={false}
                                renderCustomHeader={({
                                                       date,
                                                       changeYear,
                                                       changeMonth,
                                                       decreaseMonth,
                                                       increaseMonth,
                                                       prevMonthButtonDisabled,
                                                       nextMonthButtonDisabled,
                                                     }) => (
                                    <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          margin: "20px 16px 8px 16px",
                                          fontSize: "12px",
                                          lineHeight: "18px",
                                          fontWeight: "600"
                                        }}
                                    >
                                      {months[date.getMonth()]} {date.getFullYear()}
                                      <div className="d-flex justify-content-between align-items-center">
                                        <button className="months-button cursor-pointer" onClick={decreaseMonth}
                                                disabled={prevMonthButtonDisabled}>
                                          <svg width="6" height="18" viewBox="0 0 6 18" fill="none"
                                               xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M5.72243 12.221L2.98651 9.08207L5.72243 5.78052C6.09094 5.37408 6.09094 4.71127 5.72243 4.30483C5.34833 3.89839 4.75089 3.89839 4.38238 4.30483L0.976441 8.33797C0.792185 8.53806 0.697266 8.80694 0.697266 9.07582C0.697266 9.34469 0.792185 9.61357 0.976441 9.81366L4.38238 13.6967C4.75089 14.1032 5.35391 14.1032 5.72801 13.6967C6.09094 13.2903 6.09094 12.6275 5.72243 12.221Z"
                                                  fill={prevMonthButtonDisabled ? '#ABB5C0' : '#068EEF'}
                                            />
                                          </svg>
                                        </button>
                                        <button className="months-button cursor-pointer" onClick={increaseMonth}
                                                disabled={nextMonthButtonDisabled}>
                                          <svg width="6" height="18" viewBox="0 0 6 18" fill="none"
                                               xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M0.277575 12.221L3.01349 9.08207L0.277575 5.78052C-0.0909371 5.37408 -0.0909371 4.71127 0.277575 4.30483C0.65167 3.89839 1.24911 3.89839 1.61762 4.30483L5.02356 8.33797C5.20781 8.53806 5.30273 8.80694 5.30273 9.07582C5.30273 9.34469 5.20781 9.61357 5.02356 9.81366L1.61762 13.6967C1.24911 14.1032 0.646086 14.1032 0.271991 13.6967C-0.0909376 13.2903 -0.0909371 12.6275 0.277575 12.221Z"
                                                  fill={nextMonthButtonDisabled ? '#ABB5C0' : '#068EEF'}
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                )}
                            >
                              <div className="react-datepicker_button_clear">
                                <div className="react-datepicker_button_clear_wrapper">
                                  <button className="btn btn-link" onClick={() => this.clearEndDate()}>Clear</button>
                                </div>
                              </div>
                            </DatePicker>
                          </div>
                          <div className="mt-3">
                            <button className="btn btn-primary"
                                    onClick={() => this.addInterval()}
                            >{i18n.t('campaign_management.interval.add_time')}</button>
                            {interval.error.status && <div className="text-default error my-2">{interval.error.message}</div>}
                          </div>

                          <div className="text-default mt-4">
                            {i18n.t('campaign_management.interval.campaign_phase')} { this._currentStatus() }
                          </div>

                          {this.state.intervals?.length > 0 && <ListIntervals
                              intervals={this.state.intervals}
                              onRemove={(id) => this.removeInterval(id)}
                              onCheck={(interval) => this.onCheckInterval(interval)}
                          />}
                        </div>}
                        {!interval.status && balance > 0 && <div className="text-default mt-4">
                          {i18n.t('campaign_management.interval.campaign_phase')} { this._currentStatus() }
                        </div>}
                      </>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(error.status || hasChanges) && (
            <div className="a-card__paddings">
              <div className="cta-block">
                <div className="cta-block__central">
                  {error.status && (
                    <div>
                      <div className="cta-block__title">{error.text}</div>
                      <div className="cta-block__content">{error.description}</div>
                    </div>
                  )}
                  {hasChanges && (
                    <div className="cta-block__content">{i18n.t('campaign_management.interval.changed_campaign')}</div>
                  )}
                </div>
                {hasChanges && (
                  <div className="cta-block__cta d-flex">
                    <button className="btn btn-default mr-3"
                            onClick={() => this.cancelChanges()}
                    >{i18n.t('campaign_management.interval.cancel')}
                    </button>
                    <button className="btn btn-primary"
                            onClick={() => this.savedSettings()}
                    >{i18n.t('campaign_management.interval.save')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

CompanySettings.contextType = StoreContext;