import * as Sentry from "@sentry/react";
import Storage from "./Storage";

const getStoreInfo = (api, getRootStore, store) => new Promise((resolve) => {
  api.endpoints.ecwidInfo.getStoreInfo()
    .then(async storeInfo => {
      Sentry.setTag("email", storeInfo.email);
      Sentry.setTag("store_id", storeInfo.ecwid_store_id);
      Sentry.setTag("id", storeInfo.id);
      Storage.set('storeCreated', storeInfo.created_at);

      if (storeInfo.lang === 'it') {
          await store.changeLanguage('it');
      }

      if (Storage.get('modal-open') === null) {
          getRootStore('modalStore').openModal();
      }
      getRootStore('ecwidStore').storeInfo = storeInfo;
    })
    .catch(error => {
      console.error(error);
    })
    .finally(() => {
      resolve();
    })
});

export default getStoreInfo;
