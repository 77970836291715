class ProductsStore {
  constructor(context, getStore) {
    this.api = context.api;
    this.products = [];
    this._getStore = getStore;
  }

  getEcwidCategories = async () => {
    try {
      return await this.api.endpoints.products.getCategories();
    } catch (error) {
      throw error;
    }
  }

  getEcwidProducts = async ({keyword, category, inStock, enabled, page, isActive}) => {
    try {
      return await this.api.endpoints.products.getProducts({keyword, category, inStock, enabled, page, isActive});
    } catch (error) {
      throw error;
    }
  }

  setProductStatus = async (productId, status) => {
    try {
      return await this.api.endpoints.products.setProductStatus(productId, status);
    } catch (error) {
      throw error;
    }
  }

  setStatusesProducts = async (productsIds, status) => {
    try {
      return await this.api.endpoints.products.setStatusesProducts(productsIds, status);
    } catch (error) {
      throw error;
    }
  }
}

export default ProductsStore;
