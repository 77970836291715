import React from 'react';
import './styles/somethingWentWrong.scss';
import {useHistory} from "react-router";
import links from "../../constants/links";

function SomethingWentWrong() {
  const history = useHistory();
  const {pages: {dashboard}} = links;

  return (
    <div className="somethingWentWrongContainer">
      <div className="contentWrapper">
        <p className="title">Something went wrong</p>
        <p className="description">Please try again in a few seconds</p>
        <button
          type="button"
          className="btn btn-default"
          onClick={() => history.push(dashboard.path)}
        >Dashboard</button>
      </div>
    </div>
  )
}

export default SomethingWentWrong;
