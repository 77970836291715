import React from 'react';
import './styles/content-area-body.scss';

const ContentAreaBody = (props) => {
  const { children } = props;

  return (
    <div className="named-area__body named-area__body_custom">
      { children }
    </div>
  )
}

export default ContentAreaBody;