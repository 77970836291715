import React from "react";
import NotFound from "../../components/NotFound/NotFound";
import './styles/notFoundPage.scss';

function NotFoundPage() {
  return (
    <div className="notFoundPageWrapper">
      <NotFound/>
    </div>
  )
}

export default NotFoundPage;
