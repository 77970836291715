export default function InputInit(input) {
  input.addEventListener('input', function () {
    if (this.value) {
      this.parentNode.classList.add('field--filled');
    } else {
      this.parentNode.classList.remove('field--filled');
    }
  });
  input.addEventListener('focus', function () {
    this.parentNode.classList.add('field--focus');
  });
  input.addEventListener('blur', function () {
    this.parentNode.classList.remove('field--focus');
  });
}