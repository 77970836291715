import balanceClicks from './assets/it/№1.png';
import fillBrand from './assets/it/№2.png';
import fillUPC from './assets/it/№3.png';
import fillMPN from './assets/it/№4.png';
import fillOutMPN from './assets/it/№5.png';
import addProductTypes from './assets/it/№6.png';
import newProductType from './assets/it/№7.png';
import genderAgeColorSizeFill from './assets/it/№8.png';
import changeType from './assets/it/№9.png';
import genderAgeColorSizeFillOut from './assets/it/№10.png';
import madeInYearFill from './assets/it/№11.png';
import attributeMadeInYear from './assets/it/№12.png';
import kelkooCampaignManagement from './assets/it/№13.png';
import productListingManagement from './assets/it/№14.png';
import statistics from './assets/it/№15.png';

export default function UserGuideEn() {
    return (
        <>
            <div className="user-guide-step">
                <h3>1. Getting started</h3>
                <p>
                    Increase your sales and attract new customers to your store by including a listing of the products on Kelkoo. The app integrates Kelkoo into your Online store.
                </p>

                <p>
                    Kelkoo displays your products on its large shopping network, and you purchase clicks. With this app you can easily manage Kelkoo listings, or campaigns, of your products.
                </p>

                <span className="text-big">Kelkoo campaign management app allows you to create and manage campaigns, as well as collect statistics:</span>
                <ol>
                    <li>Select products for promotion.</li>
                    <li>Control the click balance and keep track of all the clicks purchased, used, and remained.</li>
                    <li>Schedule the campaign and choose one or more time slots.</li>
                    <li>Collect statistics of clicks and orders.</li>
                </ol>
            </div>
            <div className="user-guide-step">
                <h3>2. Kelkoo Merchant Account setup</h3>
                <p>
                    To enable Kelkoo account, please contact your IOL manager and request Kelkoo services.
                </p>
            </div>
            <div className="user-guide-step">
                <h3>3. Click Balance Section</h3>
                <span className="text-big">This section displays the clicks metrics, namely:</span>
                <ol>
                    <li>‘Total number of clicks’ shows sum of all clicks you purchased.</li>
                    <li>‘Spent clicks’ is a sum of all clicks used. The metric represents the number of times Kelkoo customers clicked on your products.</li>
                    <li>'Remaining clicks' shows the difference between the total number of clicks and the clicks spent. These clicks can be used for campaigns.</li>
                </ol>

                <p>
                    <span className="text-big">Important:</span> the campaign will be paused automatically if there are no clicks available anymore. The app updates the number of spent clicks once a day.
                </p>

                <div className="image">
                    <figure>
                        <img src={balanceClicks} alt="balance clicks"/>
                    </figure>
                </div>
            </div>
            <div className="user-guide-step">
                <h3>4. Вefore enabling a campaign: setting up products</h3>
                <p>
                    There are several product attributes that are necessary for submitting products to Kelkoo. The products may be not included, if some required attributes are missing.
                </p>
                <h4>Brand</h4>
                <p>
                    This attribute is required for products with an explicit brand or manufacturer. If the products are of their own production (hand-made) or they do not have a specific brand, this attribute can be omitted.
                </p>
                <span className="text-big">To add the Brand attribute to a product:</span>
                <ol>
                    <li>From your Online Store admin, go to Catalog → Products.</li>
                    <li>Open the product you want to edit.</li>
                    <li>Switch to the Attributes tab.</li>
                    <li>
                        Fill out the Brand field:
                    </li>
                    <div className="image">
                        <img src={fillBrand} alt="fill brand"/>
                    </div>
                    <li>Click Save to apply the changes.</li>
                </ol>

                <h4>GTIN or unique code</h4>
                <p>
                    GTIN (Global Trade Item Number) is a unique product identifier, for example, EAN or ISBN. This is the digital version of the barcode.
                </p>
                <p>
                    To add a GTIN (UPC) attribute to a product - open Product attributes and fill in the UPC (unique code) field:
                </p>
                <div className="image">
                    <img src={fillUPC} alt="fill UPC"/>
                </div>

                <h4>MPN</h4>
                <p>
                    MPN (Manufacturer Part Number) is required for all products that do not have an assigned&nbsp;
                    <a rel="noreferrer" href="https://support.ecwid.com/hc/en-us/articles/207806255-Upload-product-feed-to-Google-Shopping-manually-advanced-#gtin" target="_blank">GTIN</a>.
                    The MPN can be an alphanumeric string, so if your products do not have the manufacturer-assigned MPN, you can use your product SKU number for this attribute.
                </p>
                
                <p>To add an MPN attribute to your product, first, you’ll need to create a custom product attribute:</p>
                <ol>
                    <li>From your Online Store admin, go to Settings → Product Types.</li>
                    <li>Type MPN in the text field for a custom attribute:</li>
                    <div className="image">
                        <img src={fillMPN} alt="fill MPN"/>
                    </div>
                    <li>Click Save to apply the changes.</li>
                </ol>

                <p>Now you can add an MPN value for the product:</p>
                <ol>
                    <li>From your Online Store admin, go to <a rel="noreferrer" href="https://my.ecwid.com/#products" target="_blank">Catalog → Products.</a></li>
                    <li>Open the product you want to edit.</li>
                    <li>Switch to the Attributes tab.</li>
                    <li>Fill out the MPN field:</li>
                    <div className="image">
                        <img src={fillOutMPN} alt="fill out MPN"/>
                    </div>
                    <li>Click Save to apply the changes.</li>
                </ol>

                <h4>Gender, color, age group</h4>
                <p>
                    Attributes like gender, color, and age group are required for products submitted to the
                    Apparel & Accessories category as they help customers filter by color, age, and gender on Google Shopping.
                </p>
                <p>
                    To assign these attributes to your products, first, you need to create an Apparel & Accessories product type:
                </p>

                <ol>
                    <li>From your Online Store admin, go to Settings → Product Types.</li>
                    <li>Click Add New Product Type:</li>
                    <div className="image">
                        <img src={addProductTypes} alt="add product type"/>
                    </div>
                    <li>Expand the category tree or use the search box to find the product type suitable for your product:</li>
                    <div className="image">
                        <img src={newProductType} alt="new product type"/>
                    </div>
                    <li>Click Add. You will see a list of attributes required for the set product type:</li>
                    <div className="image">
                        <img src={genderAgeColorSizeFill} alt="gender age color size fill"/>
                    </div>
                </ol>

                <p>To fill out the attribute values:</p>
                <ol>
                    <li>From your Online Store admin, go to Catalog → Products.</li>
                    <li>Open the product you want to edit.</li>
                    <li>Switch to the Attributes tab.</li>
                    <li>Click Change type and select the new product type:</li>
                    <div className="image">
                        <img src={changeType} alt="change type"/>
                    </div>
                    <li>Fill out the attribute fields with suitable values:</li>
                    <div className="image">
                        <img src={genderAgeColorSizeFillOut} alt="gender age color size fill out"/>
                    </div>
                    <li>Click Save to apply the changes.</li>
                </ol>

                <h4>Made in, year</h4>
                <p>
                    The made in, year attributes are required for products in the Wines and Spirits category, as they
                    help customers choose products by made in and year.
                </p>
                <p>To add made in and year to your products, you first need to create an attribute like this:</p>
                <ol>
                    <li>From your Online Store admin, go to Settings → Product Types</li>
                    <li>Add a text box and enter the name of the made in attribute and year in it:</li>
                    <div className="image">
                        <img src={madeInYearFill} alt="madeIn year fill"/>
                    </div>
                    <li>Click Save to apply the changes.</li>
                </ol>
                <p>Now you can add the made in and year attribute to the products:</p>
                <ol>
                    <li>From your Online Store admin, go to → Catalog → Products.</li>
                    <li>Open the product you want to edit.</li>
                    <li>Go to the Attributes tab.</li>
                    <li>Fill in the made in and year fields:</li>
                    <div className="image">
                        <img src={attributeMadeInYear} alt="attribute madeIn year"/>
                    </div>
                    <li>Click Save to apply the changes.</li>
                </ol>

                <h4>Other attributes</h4>
                <p>
                    Kelkoo offers other attributes that can also be assigned to products:
                </p>
                <ul>
                    <li>Material — Fabric or material the product is made of (Examples: cotton, wool, silk, etc)</li>
                    <li>Green label — Eco-labels that show your products were granted some eco certification. (Examples: EU-Eco-regulation, NF Environment, FSC, Fair Trade, etc)</li>
                    <li>Energy efficiency class — Product’s energy label (Supported values: A+++, A++, A+, A, B, C, D, E, F, G)</li>
                    <li>Wine region (for spirits and wine only) — The region the product was produced in (Examples: Alsace, Dry Creek Valley, Friuli-Venezia Giulia, Languedoc-Roussillon, Piemonte, Veneto)</li>
                    <li>Wine number bottles (for spirits and wine only) — The number of bottles in the set</li>
                    <li>Wine capacity (for spirits and wine only) — The capacity of the bottle (Example: 0.75l)</li>
                    <li>Tire wet grip (for tires only — except when required by local law and regulations) — Wet grip rating for tires (Supported values: A, B, C, D, E, F, G)</li>
                    <li>Tire noise class (for tires only — except when required by local law and regulations) — Noise class rating for tires (Supported values: A, B, C)</li>
                    <li>Format (for music, films & books) — The file types (Audio formats: CD, Vinyl, Boxset, Streaming; Video formats: Blu-ray, DVD, Streaming; Examples for books: eBook, Hardback, Paperback; etc)</li>
                    <li>Genre (for music, films & books) — The style of music, films, or books (Music genres: Blues, Hip-hop, Country, Electro; Film genres: Action, Animation, Bollywood; Examples for books: Biography, Children, Fiction; etc)</li>
                </ul>
                <p>
                    The complete list of Kelkoo parameters and attributes can be found&nbsp;
                    <a rel="noreferrer"
                       target="_blank"
                       href="https://developers.kelkoogroup.com/app/documentation/navigate/_merchant/merchantProductData/_/_/ProductDataSpecs">
                        here
                    </a>
                </p>
            </div>
            <div className="user-guide-step">
                <h3>5. Kelkoo Campaign Management</h3>
                <p>In this section, you can manage the status of Kelkoo campaigns. It is possible to activate or pause the campaign here. The campaign will be paused automatically if there are no clicks available anymore, or if the click balance is negative. You can disable campaigns at any moment by changing the status to ‘Disabled’.</p>
                <p>By default, the campaign is active when its status is ‘Enabled’. You can also create a campaign schedule. To schedule a campaign, enable ‘Campaign schedule’. Select one or a few time slots for your campaign. The campaign will be paused if the current date is not within one of the selected intervals.</p>
                <p>Set start and end dates for each time slot. The dates are displayed in the time zone of your store control panel. You can change the start date of each slot anytime before this day. You can also change the end date to extend your campaign or finish it earlier.</p>
                <p>
                    <span className="text-big">Important:</span> The changes in the status will not be applied immediately. The system monitors changes several times a day.
                </p>
                <div className="image">
                    <figure>
                        <img src={kelkooCampaignManagement} alt="kelkoo campaign management"/>
                    </figure>
                </div>
            </div>
            <div className="user-guide-step">
                <h3>6. Product Listing Management Section</h3>
                <p>In this section, you can select the products to upload to Kelkoo.
                    All products from your Ecwid store are displayed here (see fig. 3)
                </p>
                <ul className="list-none">
                    <li>1-  Product name</li>
                    <li>2 - SKU code</li>
                    <li>3 - Product status (whether it is visible for customers, or not)</li>
                    <li>4 - Stock availability, the number of products in stock</li>
                    <li>5, 6 - You can filter and search by product names, just like in the Ecwid store</li>
                    <li>7 - Kelkoo listing status. If it is enabled, the product will be uploaded to Kelkoo</li>
                    <li>8, 9 - You can select several products and mass update their status</li>
                </ul>
                <p>
                    <span className="text-big">Important:</span> Kelkoo listing status updates several times a day in accordance with the schedule (7:00, 11:00, 15:00, 23:00 UTC). Changes (quantity, name, sku, attributes, etc) will be applied if they were made prior to one of the update time interval.
                </p>
                <div className="image">
                    <figure>
                        <img src={productListingManagement} alt="product listing management"/>
                    </figure>
                </div>
            </div>
            <div className="user-guide-step">
                <h3>7. Campaign Statistics Section</h3>
                <p>This section displays the campaign statistics: number of clicks used, number of orders in the store. Choose a period for which you would like to review the statistics. </p>
                <p>
                    The section offers several ways to select your statistics date ranges: Last 7 days, Last 30 days, Last year, All time
                </p>
                <div className="image">
                    <img src={statistics} alt="Statistics"/>
                </div>
            </div>
        </>
    );
}