import Storage from "./Storage";

const getEcwidInfo = (api, getRootStore) => new Promise((resolve) => {
  api.endpoints.ecwidInfo.getEcwidInfo()
    .then(ecwidInfo => {
      const {currency, currencyPrefix, currencySuffix, dateFormat, timeFormat, timezone} = ecwidInfo;
      Storage.set('currency', currency);
      Storage.set('currencyPrefix', currencyPrefix);
      Storage.set('currencySuffix', currencySuffix);
      Storage.set('dateFormat', dateFormat);
      Storage.set('timeFormat', timeFormat);
      Storage.set('timezone', timezone);
    })
    .catch(error => {
      console.error(error);
    })
    .finally(() => {
      resolve();
    })
});

export default getEcwidInfo;
