import React, {forwardRef, memo, useEffect, useState} from "react";
import './styles/list-intervals-item.scss';
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import {MONTHS} from "../../../../../utils/graph";
import cx from "classnames";

import {ReactComponent as RemoveSvg} from './assets/remove.svg';
import {ReactComponent as EditSvg} from './assets/edit.svg';
import {ReactComponent as CheckSvg} from './assets/check.svg';
import {ReactComponent as CloseSvg} from './assets/close.svg';
import i18n from "i18next";
import {_periodOverlaps} from "../utils/IntervalsService";
import {useStore} from "../../../../../store";

function ListIntervalsItem(props) {
    const [interval, setInterval] = useState({
        id: null,
        startDate: null,
        endDate: null
    });

    const [error, setError] = useState({
        status: false,
        message: ''
    })

    useEffect(() => {
        setInterval({
            id: props.interval.id,
            startDate: moment.utc(props.interval.startDate).toDate(),
            endDate: moment.utc(props.interval.endDate).toDate()
        });
    }, [props.interval]);

    const [isEdit, setIsEdit] = useState(false);

    const {
        ecwidStore: {
            storeInfo
        },
    } = useStore();

    const onCheck = () => {
        if (!interval.startDate || !interval.endDate) {
            setError({
                status: true,
                message: i18n.t('campaign_management.interval.intervals_required')
            });

            return;
        } else if (_periodOverlaps(interval, props.intervals.filter(i => i.id !== interval.id))) {
            setError({
                status: true,
                message: i18n.t('campaign_management.interval.intervals_period_overlaps')
            });

            return;
        } else {
            setError({
                status: false,
                message: ''
            });
        }

        setIsEdit(false);
        props.onCheck(interval);
    }

    const onClose = () => {
        setError({
            status: false,
            message: ''
        });
        setInterval({
            id: props.interval.id,
            startDate: moment.utc(props.interval.startDate).toDate(),
            endDate: moment.utc(props.interval.endDate).toDate()
        });
        setIsEdit(false);
    }

    const getIntervalsToString = () => {
        const start = moment.utc(interval.startDate).local().format('DD.MM.YYYY');
        const end = moment.utc(interval.endDate).local().format('DD.MM.YYYY');

        return `${start} - ${end}`;
    }

    const CustomDatePicker = ({date,changeYear,changeMonth,decreaseMonth,increaseMonth,prevMonthButtonDisabled,nextMonthButtonDisabled,}) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "20px 16px 8px 16px",
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontWeight: "600"
                }}
            >
                {MONTHS[date.getMonth()]} {date.getFullYear()}
                <div className="d-flex justify-content-between align-items-center">
                    <button className="months-button cursor-pointer" onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}>
                        <svg width="6" height="18" viewBox="0 0 6 18" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M5.72243 12.221L2.98651 9.08207L5.72243 5.78052C6.09094 5.37408 6.09094 4.71127 5.72243 4.30483C5.34833 3.89839 4.75089 3.89839 4.38238 4.30483L0.976441 8.33797C0.792185 8.53806 0.697266 8.80694 0.697266 9.07582C0.697266 9.34469 0.792185 9.61357 0.976441 9.81366L4.38238 13.6967C4.75089 14.1032 5.35391 14.1032 5.72801 13.6967C6.09094 13.2903 6.09094 12.6275 5.72243 12.221Z"
                                  fill={prevMonthButtonDisabled ? '#ABB5C0' : '#068EEF'}
                            />
                        </svg>
                    </button>
                    <button className="months-button cursor-pointer" onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}>
                        <svg width="6" height="18" viewBox="0 0 6 18" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M0.277575 12.221L3.01349 9.08207L0.277575 5.78052C-0.0909371 5.37408 -0.0909371 4.71127 0.277575 4.30483C0.65167 3.89839 1.24911 3.89839 1.61762 4.30483L5.02356 8.33797C5.20781 8.53806 5.30273 8.80694 5.30273 9.07582C5.30273 9.34469 5.20781 9.61357 5.02356 9.81366L1.61762 13.6967C1.24911 14.1032 0.646086 14.1032 0.271991 13.6967C-0.0909376 13.2903 -0.0909371 12.6275 0.277575 12.221Z"
                                  fill={nextMonthButtonDisabled ? '#ABB5C0' : '#068EEF'}
                            />
                        </svg>
                    </button>
                </div>
            </div>
        )
    }

    const CustomStartDateInput = forwardRef(({value, onClick, onChange}, ref) => (
        <div className="fieldset fieldset--with-label"
             style={
                 {
                     width: '120px'
                 }
             }
        >
            <div className="field field--large field--filled"
                 disabled={storeInfo?.current_status?.id === props.interval.id}
                 onClick={onClick}>
                <span className="fieldset__svg-icon"/>
                <label className="field__label">{i18n.t('campaign_management.interval.start_date')}</label>
                <input type="text"
                       id="startDateIntervalCompany"
                       className="field__input"
                       disabled={storeInfo?.current_status?.id === props.interval.id}
                       ref={ref} value={value}
                       readOnly
                       onChange={onChange}
                />
                <div className="field__placeholder">{i18n.t('campaign_management.interval.start_date')}</div>
                <span className="field-state--success">
                  <svg xmlns="http://www.w3.org/2000/svg" width="26px"
                       height="26px" viewBox="0 0 26 26" focusable="false">
                  <path
                      d="M5 12l5.02 4.9L21.15 4c.65-.66 1.71-.66 2.36 0 .65.67.65 1.74 0 2.4l-12.3 14.1c-.33.33-.76.5-1.18.5-.43 0-.86-.17-1.18-.5l-6.21-6.1c-.65-.66-.65-1.74 0-2.41.65-.65 1.71-.65 2.36.01z"/></svg>
                </span>
                <span className="field-state--close">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 16 16" enableBackground="new 0 0 16 16"
                     xmlSpace="preserve" focusable="false">
                  <path
                      d="M15.6,15.5c-0.53,0.53-1.38,0.53-1.91,0L8.05,9.87L2.31,15.6c-0.53,0.53-1.38,0.53-1.91,0c-0.53-0.53-0.53-1.38,0-1.9l5.65-5.64L0.4,2.4c-0.53-0.53-0.53-1.38,0-1.91c0.53-0.53,1.38-0.53,1.91,0l5.64,5.63l5.74-5.73c0.53-0.53,1.38-0.53,1.91,0c0.53,0.53,0.53,1.38,0,1.91L9.94,7.94l5.66,5.65C16.12,14.12,16.12,14.97,15.6,15.5z"/></svg>
                </span>
            </div>
            <div className="field__error" aria-hidden="true" style={{display: 'none'}}/>
        </div>
    ));

    const CustomEndDateInput = forwardRef(({value, onClick, onChange}, ref) => (
        <div className="fieldset fieldset--with-label"
             style={
                 {
                     width: '120px'
                 }
             }
        >
            <div className="field field--large field--filled"
                 onClick={onClick}>
                <span className="fieldset__svg-icon"/>
                <label className="field__label">{i18n.t('campaign_management.interval.end_date')}</label>
                <input type="text"
                       id="startDateIntervalCompany"
                       className="field__input"
                       ref={ref} value={value}
                       readOnly
                       onChange={onChange}
                />
                <div className="field__placeholder">{i18n.t('campaign_management.interval.end_date')}</div>
                <span className="field-state--success">
                  <svg xmlns="http://www.w3.org/2000/svg" width="26px"
                       height="26px" viewBox="0 0 26 26" focusable="false">
                  <path
                      d="M5 12l5.02 4.9L21.15 4c.65-.66 1.71-.66 2.36 0 .65.67.65 1.74 0 2.4l-12.3 14.1c-.33.33-.76.5-1.18.5-.43 0-.86-.17-1.18-.5l-6.21-6.1c-.65-.66-.65-1.74 0-2.41.65-.65 1.71-.65 2.36.01z"/></svg>
                </span>
                <span className="field-state--close">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 16 16" enableBackground="new 0 0 16 16"
                     xmlSpace="preserve" focusable="false">
                  <path
                      d="M15.6,15.5c-0.53,0.53-1.38,0.53-1.91,0L8.05,9.87L2.31,15.6c-0.53,0.53-1.38,0.53-1.91,0c-0.53-0.53-0.53-1.38,0-1.9l5.65-5.64L0.4,2.4c-0.53-0.53-0.53-1.38,0-1.91c0.53-0.53,1.38-0.53,1.91,0l5.64,5.63l5.74-5.73c0.53-0.53,1.38-0.53,1.91,0c0.53,0.53,0.53,1.38,0,1.91L9.94,7.94l5.66,5.65C16.12,14.12,16.12,14.97,15.6,15.5z"/></svg>
                </span>
            </div>
            <div className="field__error" aria-hidden="true" style={{display: 'none'}}/>
        </div>
    ));

    return (
        <div className="list-intervals_item">
            {isEdit && <span className="text-default">Change interval</span>}
            <div className="d-flex justify-content-between align-items-center">
                <div className="list-intervals_item__text text-default">
                    {!isEdit &&
                        <span className={cx({"text-bold": storeInfo?.current_status?.id === props.interval.id})}>
                            {getIntervalsToString()}
                        </span>
                    }
                    {isEdit && (
                        <>
                            <div className="d-flex mt-2">
                                <DatePicker
                                    customInput={<CustomStartDateInput/>}
                                    dateFormat="dd.MM.yyyy"
                                    locale="en-GB"
                                    selected={interval.startDate}
                                    disabled={storeInfo?.current_status?.id === props.interval.id}
                                    minDate={moment().toDate()}
                                    maxDate={moment(interval.endDate).subtract(1, 'day').toDate()}
                                    onChange={(date) => setInterval(prev => {
                                        return {
                                            ...prev,
                                            startDate: date
                                        }
                                    })}
                                    shouldCloseOnSelect={false}
                                    renderCustomHeader={CustomDatePicker}
                                >
                                    <div className="react-datepicker_button_clear">
                                        <div className="react-datepicker_button_clear_wrapper">
                                            <button className="btn btn-link" onClick={() => setInterval(prev => {
                                                return {
                                                    ...prev,
                                                    startDate: null
                                                }
                                            })}>Clear</button>
                                        </div>
                                    </div>
                                </DatePicker>
                                <DatePicker
                                    customInput={<CustomEndDateInput/>}
                                    dateFormat="dd.MM.yyyy"
                                    locale="en-GB"
                                    selected={interval.endDate}
                                    minDate={
                                        interval.startDate ?
                                            moment().toDate() > moment(interval.startDate).toDate() ?
                                                moment().add(1, 'day').toDate() : moment(interval.startDate).add(1, 'day').toDate() :
                                            moment().add(1, 'day').toDate()
                                    }
                                    onChange={(date) => setInterval(prev => {
                                        return {
                                            ...prev,
                                            endDate: date
                                        }
                                    })}
                                    shouldCloseOnSelect={false}
                                    renderCustomHeader={CustomDatePicker}
                                >
                                    <div className="react-datepicker_button_clear">
                                        <div className="react-datepicker_button_clear_wrapper">
                                            <button className="btn btn-link" onClick={() => setInterval(prev => {
                                                return {
                                                    ...prev,
                                                    endDate: null
                                                }
                                            })}>Clear</button>
                                        </div>
                                    </div>
                                </DatePicker>
                            </div>
                        </>
                    )}
                </div>
                <div className="list-intervals_item__buttons">
                    {!isEdit && <div className="list-intervals_item__buttons-not-edit">
                        {moment().endOf('day') <= moment(props.interval.endDate).endOf('day') && <EditSvg onClick={() => setIsEdit(!isEdit)}/>}
                        <RemoveSvg onClick={() => props.onRemove(interval.id)}/>
                    </div>}
                    {isEdit && <div className="list-intervals_item__buttons-edit">
                        <CheckSvg id="checkSvg" onClick={() => onCheck()}/>
                        <CloseSvg id="closeSvg" onClick={() => onClose()}/>
                    </div>}
                </div>
            </div>
            {error.status && <div className="text-default error my-2">{error.message}</div>}
        </div>
    );
}

export default memo(ListIntervalsItem);