import apiList from "../../constants/apiList";
import moment from "moment";
import {AppEnvList} from "../../constants/AppEnvList";

// eslint-disable-next-line
export default class {
  constructor(axios) {
    this.axios = axios;

    if (process.env.APP_ENV !== AppEnvList.prod) {
      delete this.addClicks;
      delete this.spentClicks;
    }
  };

  async getEcwidInfo() {
    try {
      const response = await this.axios({
        method: 'get',
        url: apiList.ecwidInfo,
      })
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async getStoreInfo() {
    try {
      const response = await this.axios({
        method: 'get',
        url: apiList.storeInfo,
      })
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async changeStatusCompany({campaign_management, campaign_schedule, intervals}) {

    intervals = intervals ? JSON.stringify(JSON.parse(intervals).map(i => {
      return {
        id: i.id,
        startDate: moment(i.startDate).format('YYYY-MM-DD'),
        endDate: moment(i.endDate).format('YYYY-MM-DD'),
      }
    })) : null;

    try {
      const response = await this.axios({
        method: 'post',
        url: apiList.changeStatusCompany,
        params: {
          campaign_management: campaign_management,
          campaign_schedule: campaign_schedule,
          intervals: intervals
        }
      })
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async addClicks() {
    try {
      const response = await this.axios({
        method: 'post',
        url: apiList.addClicks,
      })
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async spentClicks() {
    try {
      const response = await this.axios({
        method: 'post',
        url: apiList.spentClicks,
      })
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async generateProductsXML() {
    try {
      const response = await this.axios({
        method: 'post',
        url: apiList.generateProductsXML,
      })
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
}