import moment from "moment/moment";

export function _periodOverlaps(testPeriod, periods) {
    for (let i = 0; i < periods.length; i++) {
        const period = periods[i];
        period.startDate = moment.utc(period.startDate).local().startOf('day');
        period.endDate = moment.utc(period.endDate).local().endOf('day');

        if (period.startDate <= testPeriod.startDate && period.endDate >= testPeriod.startDate) {
            return true;
        }
        if (period.startDate >= testPeriod.startDate && period.startDate <= testPeriod.endDate) {
            return true;
        }
    }

    return false;
}

export function _periodInclude(currentDate, periods) {
    for (let i = 0; i < periods.length; i++) {
        const period = periods[i];
        period.startDate = moment.utc(period.startDate).local().startOf('day');
        period.endDate = moment.utc(period.endDate).local().endOf('day');

        if (currentDate >= period.startDate && currentDate <= period.endDate) {
            return i;
        }
    }

    return -1;
}