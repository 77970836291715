import React, {createContext, useContext} from "react";
import getParams from "../utils/getParams";
import Api from "../api";
import RootStore from "./RootStore";
import getEcwidInfo from "../utils/getEcwidInfo";
import Storage from "../utils/Storage";
import i18n from "i18next";
import {defaultRedirect} from "../utils/history";
import getStoreInfo from "../utils/getStoreInfo";

function initAppState() {
    let appState = getParams('app_state');
    try {
        appState = JSON.parse(appState);
        if (!appState) {
            return false;
        }

        const { keyword, category, inStock, enabled, page } = appState;

        const filter = {};

        if (keyword !== undefined) {
            filter.keyword = keyword;
        }
        if (+category !== undefined) {
            filter.category = category;
        }
        if (inStock !== undefined) {
            filter.inStock = inStock;
        }
        if (enabled !== undefined) {
            filter.enabled = enabled;
        }
        if (page !== undefined) {
            filter.page = page;
        }

        return filter;
    } catch (e) {
        console.error('I can\'t read app_state');
    }
}

export const StoreContext = createContext("store");

let appState = null;

const createStore = async () => {
    appState = initAppState();

    const storagePayload = Storage.get('payload');
    const payload = getParams('payload') || storagePayload;

    if (payload) {
        Storage.set('payload', payload);
    }

    const EcwidApp = window.EcwidApp;

    EcwidApp.init({
        app_id: process.env.REACT_APP_APP_ID,
        autoloadedflag: true,
        autoheight: true,
    });

    const getRootStore = (storeName) => {
        return store[storeName];
    };
    const api = new Api({payload, getRootStore});
    const store = new RootStore({api, i18n});

    await getEcwidInfo(api, getRootStore);
    await getStoreInfo(api, getRootStore, store);
    defaultRedirect();
    api.setRequestCurrency();

    return ({children}) => (
        <StoreContext.Provider value={store}>
            {children}
        </StoreContext.Provider>
    );
};

const useStore = () => useContext(StoreContext);

export {createStore, useStore, appState};
