import apiList from "../../constants/apiList";
import moment from "moment";
import {getType} from "mobx-state-tree";

// eslint-disable-next-line
export default class {
  constructor(axios) {
    this.axios = axios;
  };

  async getStatistics(startDate, endDate) {
    try {
      const response = await this.axios({
        method: 'get',
        url: apiList.statistics,
        params: {
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD')
        }
      })
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
}