// eslint-disable-next-line
export default {
  ecwidInfo: '/profile',
  storeInfo: '/storeInfo',

  changeStatusCompany: '/changeStatusCompany',

  ecwidProducts: '/products',
  ecwidCategories: '/categories',
  ecwidSetProductStatus: (id) => '/products/' + id,
  ecwidSetProductsStatuses: '/products',

  statistics: '/statistics',

  addClicks: '/addClicks',
  spentClicks: '/spentClicks',
  generateProductsXML: '/productsXml'
}