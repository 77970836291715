import React from "react";
import {useHistory} from "react-router";
import links from "../../constants/links";
import './styles/notFound.scss';

function NotFound() {
  const history = useHistory();
  const {pages: {dashboard}} = links;

  return (
    <div className="somethingWentWrongContainer">
      <div className="contentWrapper">
        <p className="title">404: Page not found</p>
        <p className="description">Page you are looking for does not exist</p>
        <button
          type="button"
          className="btn btn-default"
          onClick={() => history.push(dashboard.path)}
        >Dashboard</button>
      </div>
    </div>
  )
}

export default NotFound;