import React, {useEffect} from 'react';
import {ReactComponent as HeaderIcon} from './assets/headerIcon.svg';
import {ReactComponent as CloseIcon} from './assets/closeIcon.svg';
import './styles/dialog.scss';
import {observer} from "mobx-react";
import {useStore} from "../../store";

function Dialog(props) {
    const {
        modalStore
    } = useStore();

    useEffect(() => {
        if (modalStore.isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [modalStore.isOpen])

    const onCloseWizard = () => {
        modalStore.closeModal();
    }

    return (
        <>
            {modalStore.isOpen && (
                <div className="main-overlay">
                    <div className="main-popup main-popup_center main-popup_animation customPopup">
                        <div className="main-popup__container customPopupContainer">
                            <div className="main-popup__subcontainer">
                                <div className="main-popup__close">
                                    <CloseIcon
                                        onClick={() => onCloseWizard()}
                                    />
                                </div>
                                <div className="main-popup__logo">
                                    <HeaderIcon/>
                                </div>
                                <h2 className="main-popup__title">
                                    <span className="gwt-InlineHTML">{props.title}</span>
                                </h2>
                                <div className="main-popup-wrapper">
                                    <div className="main-popup__text" aria-hidden="true">
                                        {props.children}
                                    </div>
                                </div>
                                <div className="main-popup__buttons">
                                    <button type="button"
                                            onClick={() => onCloseWizard()}
                                            className="btn btn-default btn-medium"
                                    >
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default observer(Dialog);