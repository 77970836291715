import React from 'react';
import './styles/header-setting-page.scss';
import {useStore} from "../../../../store";
import i18n from "i18next";

const HeaderSettingPage = (props) => {
    const {title, description} = props;

    const {
        modalStore
    } = useStore();

    return (
        <div className="settings-page__header mt-5">
            <div className="settings-page__titles settings-page__titles--left">
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <h1 className="settings-page__title m-0">{title}</h1>
                    <button className="btn btn-primary btn-medium"
                            onClick={() => modalStore.openModal()}>{i18n.t('modalButton')}
                    </button>
                </div>
                <div className="settings-page__subtitle">{description}</div>
            </div>
        </div>
    )
}

export default HeaderSettingPage;