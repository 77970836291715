import React from "react";
import './styles/test-store.scss';
import {StoreContext} from "../../store";

export default class TestStore extends React.Component {
  state = {
    storeInfo: null
  }

  componentDidMount() {
    const {
      ecwidStore: {
        storeInfo,
      },
    } = this.context;

    this.setState(() => ({
      storeInfo: storeInfo
    }));
  }

  addClicks() {
    const {
      ecwidStore: {
        addClicks,
      },
    } = this.context;

    addClicks().then(() => {
      console.log('Клики добавлены');
      document.location.reload();
    });
  }

  spentClicks() {
    const {
      ecwidStore: {
        spentClicks,
      },
    } = this.context;

    spentClicks().then(() => {
      console.log('Клики потрачены');
      document.location.reload();
    });
  }

  generateProductsXML() {
    const {
      ecwidStore: {
        generateProductsXML,
      },
    } = this.context;

    generateProductsXML().then(() => {
      console.log('Xml отправлен на генерацию');
      alert('Wait a few seconds for the updated xml to be generated')
    });
  }

  render() {
    const { storeInfo } = this.state;

    return (
      <div className="a-card a-card--normal">
        <div className="a-card__paddings">
          <div className="d-flex flex-column test-functions">
            <h4>
              TEST ZONE. This section is displayed only in the sandbox module.
            </h4>
            <div className="mt-2">
              <button className="btn btn-default mr-2" onClick={() => {
                this.addClicks()
              }}>
                +10 clicks
              </button>
              <button className="btn btn-default mr-2" onClick={() => {
                this.spentClicks()
              }}>
                -10 clicks
              </button>
              <button className="btn btn-default" onClick={() => {
                this.generateProductsXML()
              }}>
                Generate product feed in xml
              </button>
            </div>
            <div className="d-flex flex-column mt-3">
              <span className="text-default">Feed URL</span>
              <a target='_blank'
                 rel="noreferrer"
                 href={storeInfo && storeInfo.s3_products_xml_url}>
                {storeInfo && storeInfo.s3_products_xml_url}
              </a>
              <div className="text-default mt-2">Last generated on: {storeInfo && storeInfo.last_products_xml_generated_at}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

TestStore.contextType = StoreContext;