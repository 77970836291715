class StatisticsStore {
  constructor(context, getStore) {
    this.api = context.api;
    this.products = [];
    this._getStore = getStore;
  }

  getStatistics = async (startDate, endDate) => {
    try {
      return await this.api.endpoints.statistics.getStatistics(startDate, endDate);
    } catch (error) {
      throw error;
    }
  }
}

export default StatisticsStore;
