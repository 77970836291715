import {makeAutoObservable} from "mobx";

class EcwidStore {
    loading = false;

    constructor({ api }, initialValue) {
        this.api = api;
        this.data = initialValue || null;

        this.storeInfo = null;

        makeAutoObservable(this);
    }

    setStoreInfo(value) {
        this.storeInfo = value;
    }

    getStoreInfo = async () => {
        try {
            return await this.api.endpoints.ecwidInfo.getStoreInfo();
        } catch (error) {
            throw error;
        }
    }

    changeStatusCompany = async (params) => {
        try {
            return await this.api.endpoints.ecwidInfo.changeStatusCompany(params);
        } catch (error) {
            throw error;
        }
    }

    addClicks = async () => {
        try {
            return await this.api.endpoints.ecwidInfo.addClicks();
        } catch (error) {
            throw error;
        }
    }

    spentClicks = async () => {
        try {
            return await this.api.endpoints.ecwidInfo.spentClicks();
        } catch (error) {
            throw error;
        }
    }

    generateProductsXML = async () => {
        try {
            return await this.api.endpoints.ecwidInfo.generateProductsXML();
        } catch (error) {
            throw error;
        }
    }
}

export default EcwidStore;
