import React from "react";
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import * as Utils from "../../../utils/graph";
import 'chartjs-adapter-moment';
import moment from "moment";
import i18n from "i18next";

Chart.register(zoomPlugin);

export default class Graph extends React.Component {
  ctx;

  componentDidMount() {
    this.ctx = new Chart(document.getElementById('graph'), this.getConfig());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.ctx.update();
  }

  componentWillUnmount() {
    this.ctx.destroy();
  }

  getConfig() {
    const {
      leftDataPoints,
      spentDataPoints,
      orderDataPoints,
      labels
    } = this.props;

    let start_date = new Date(labels[0]);
    let end_date = new Date(labels[labels.length-1]);

    const data = {
      labels: labels,
      datasets: [
        {
          label: i18n.t('statistics.remaining_clicks_graph_name'),
          data: leftDataPoints,
          borderColor: Utils.CHART_COLORS.red,
          borderWidth: 4,
          fill: false,
          cubicInterpolationMode: 'monotone',
          tension: 0.4
        },
        {
          label: i18n.t('statistics.spent_clicks_graph_name'),
          data: spentDataPoints,
          borderColor: Utils.CHART_COLORS.yellow,
          borderWidth: 4,
          fill: false,
          cubicInterpolationMode: 'monotone',
          tension: 0.4
        },
        {
          label: i18n.t('statistics.number_orders_graph_name'),
          data: orderDataPoints,
          borderColor: Utils.CHART_COLORS.green,
          borderWidth: 4,
          fill: false,
          cubicInterpolationMode: 'monotone',
          tension: 0.4
        },
      ]
    };

    return {
      type: 'line',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          zoom: {
            limits: {
              x: {min: moment(start_date).subtract(2, 'days'), max: moment(end_date).add(2, 'days')}
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              mode: 'x'
            },
            pan: {
              enabled: true,
              mode: 'x',
            }
          },
          title: {
            display: false,
          },
          legend: {
            labels: {
              padding: 20,
              usePointStyle: true,
              pointStyle: 'line',
              font: {
                size: 14
              }
            }
          },
          tooltip: {
            usePointStyle: true,
            callbacks: {
              labelPointStyle: function(context) {
                return {
                  pointStyle: 'line',
                  rotation: 0
                };
              }
            }
          }
        },
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day'
            }
          },
          y: {
            beginAtZero: true
          }
        }
      },
    };
  }

  render() {
    return (
      <div className="chart-container" style={{position: 'relative', height: '450px'}}>
        <canvas id="graph"/>
      </div>
    )
  }
}