import React from "react";
import './styles/product-item.scss';
import i18n from "i18next";
import {isNumber} from "chart.js/helpers";

export default class FilterList extends React.Component {
  state = {
    status: this.props.status,
    selected: this.props.isChecked
  }

  handleSetChecked = (value, productId) => {
    const valueToTinyInt = value ? 1 : 0;

    this.setState(() => ({
      status: value
    }));

    this.props.onSetChecked(valueToTinyInt, productId);

    this.props.onSetProductStatus(productId, valueToTinyInt);
  }

  openProductInEcwid = () => {
    this.props.openProductInEcwid(this.props.productId);
  }

  handleSetCheckedSelect = (event) => {
    const productId = +event.target.value;
    const checked = event.target.checked;

    this.setState(() => ({
      selected: checked
    }), () => {
      this.props.handleSetCheckedSelect(productId, checked);
    });
  }

  render() {
    const { title, productId, status, productSku, imageUrl, isChecked, quantity, enabled } = this.props;

    return (
      <div className="list-element list-element--compact list-element--has-hover">
        <div className="list-element__toggle align-self-center">
          <input type="checkbox"
                 id={'checkboxProduct' + productId}
                 checked={isChecked}
                 value={productId}
                 onChange={(event) => this.handleSetCheckedSelect(event)}
                 className="list-element__toggle-checkbox"
          />
          <label htmlFor={'checkboxProduct' + productId} className="list-element__toggle-label"/>
        </div>
        {!imageUrl && <div className="product__image cursor-pointer" onClick={this.openProductInEcwid}>
          <div className="gwt-Label product-placeholder colored-background-1">{ title[0].toUpperCase() }</div>
        </div>
        }
        {imageUrl && <div className="list-element__image cursor-pointer" onClick={this.openProductInEcwid}>
          <img src={imageUrl} alt={title}/>
        </div>
        }
        <div className="list-element__content">
          <div className="list-element__info">
            <div className="list-element__header">
              <div className="list-element__main-info">
                <div className="list-element__title cursor-pointer" onClick={this.openProductInEcwid}>
                  <span>{title}</span>
                </div>
                <div className="list-element__description cursor-pointer" onClick={this.openProductInEcwid}>
                  <span className="muted">{productSku}</span>
                </div>
              </div>
              <div className="list-element__price"/>
            </div>
            <div className="list-element__data-row">
              <div className="d-flex">
                {enabled && (<span className="product-enabled">{i18n.t('product_listing.enabled')}</span>)}
                {!enabled && (<span className="product-disabled">{i18n.t('product_listing.disabled')}</span>)}
                {isNumber(quantity) && quantity > 0 && (<span className="quantity-has">{i18n.t('product_listing.in_stock')}: {quantity} {i18n.t('product_listing.in_stock_it')}</span>)}
                {isNumber(quantity) && quantity <= 0 && (<span className="quantity-not">{i18n.t('product_listing.out_stock')}</span>)}
                {quantity === null && (<span className="quantity-has">{i18n.t('product_listing.in_stock')}</span>)}
              </div>
            </div>
          </div>
          <div className="list-element__actions">
            <label className="checkbox tiny">
              <input type="checkbox"
                     checked={status}
                     onChange={(event) => this.handleSetChecked(event.target.checked, productId)}
              />
              <div data-on="enabled" data-off="disabled">
                <div/>
              </div>
            </label>
          </div>
        </div>
      </div>
    )
  }
}