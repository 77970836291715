import React from "react";
import ProductItem from "./ProductItem/ProductItem";
import {appState, StoreContext} from "../../store";
import './styles/filter-list.scss';
import debounce from "debounce";
import EmptyProductsList from "./EmptyProductsList/EmptyProductsList";
import InputInit from "../../utils/inputInit";
import i18n from "i18next";

export default class FilterList extends React.Component {
  state = {
    perPage: 20,
    paginateSettings: {
      startProductNumber: 0,
      lastProductNumber: 0
    },
    productsSelected: [],
    products: [],
    categories: [],
    filters: {
      keyword: '',
      category: 'all',
      inStock: 'all',
      enabled: 'all',
      isActive: false,
      page: 1
    },
    pageChanged: false,
    loadingProducts: true,
    loadingCategories: true,
    storeInfo: null
  };

  componentDidMount() {
    if (appState) {
      this.setState((state) => ({
        filters: {
          ...state.filters,
          ...appState
        }
      }), () => {
        this.loadGetCategories();
        this.loadGetProducts();
      });
    } else {
      this.loadGetCategories();
      this.loadGetProducts();
    }

    InputInit(document.querySelectorAll('.filtered-list .field__input')[0]);

    const {
      ecwidStore: {
        storeInfo,
      },
    } = this.context;

    this.setState((state) => ({
      storeInfo: storeInfo
    }));
  }

  setLoadingProducts(value) {
    this.setState(() => ({
      loadingProducts: value
    }))
  }

  setLoadingCategories(value) {
    this.setState(() => ({
      loadingCategories: value
    }))
  }

  loadGetProducts() {
    this.setLoadingProducts(true);
    this.getProducts({
      ...this.state.filters
    }).then(products => {
      this.setState(() => ({
        products: products
      }));
    }).finally(() => {
      this.setState((state) => ({
        paginateSettings: {
          startProductNumber: (state.perPage * state.filters.page - state.perPage) + 1,
          lastProductNumber: (state.perPage * state.filters.page - state.perPage) + state.products.count
        }
      }));
      this.setLoadingProducts(false);
      if (this.state.pageChanged) {
        document.querySelector('.filtered-list__items').scrollIntoView({
          behavior: "auto",
          block: 'start'
        });
      }
    });
  }

  loadGetCategories() {
    this.setLoadingCategories(true);
    this.getCategories().then(categories => {
      this.setState(() => ({
        categories: categories
      }));
    }).finally(() => {
      this.setLoadingCategories(false);
    });
  }

  async getCategories() {
    const {
      productStore: {
        getEcwidCategories,
      },
    } = this.context;

    return await getEcwidCategories();
  }

  async getProducts(filter) {
    const {
      productStore: {
        getEcwidProducts,
      },
    } = this.context;

    return await getEcwidProducts(filter);
  }

  setProductStatus = async (productId, status) => {
    const {
      productStore: {
        setProductStatus,
      }
    } = this.context;

    return await setProductStatus(productId, status);
  }

  setStatusesProducts = (status) => {
    const {
      productStore: {
        setStatusesProducts,
      }
    } = this.context;

    const productsIds = this.state.productsSelected;

    setStatusesProducts(productsIds, status).then(() => {
      this.setState((state) => ({
        products: {
          ...state.products,
          items: state.products?.items?.map(product => productsIds.includes(product.id) ? {
              ...product,
              status: !!status
            } : product
          )
        }
      }));
    });
  }

  initCollapses(event) {
    event.target.parentNode.classList.toggle('opened');
  }

  onSetChecked = (status, productId) => {
    this.setState((state) => ({
      products: {
        ...state.products,
        items: state.products?.items?.map(product => product.id === productId ? {
          ...product,
          status: !!status
        } : product)
      }
    }));

    this.setState(() => ({
      loadingProducts: false
    }));
  };

  handleClearAllProducts(event) {
    if (this.state.productsSelected.length === 0) {
      return;
    }

    this.setState(() => ({
      productsSelected: []
    }));
  }

  handleSelectAllProducts(event) {
    // Выделить все
    this.setState((state) => ({
      productsSelected: [
        ...new Set([
          ...state.productsSelected,
          ...state.products.items.map(product => product.id)
        ])
      ]
    }));
  }

  handleSetCheckedSelect = (productId, checked) => {
    if (checked) {
      this.setState((state) => ({
        productsSelected: [
          ...state.productsSelected,
          productId
        ]
      }));
    } else {
      this.setState((state) => ({
        productsSelected: [
          ...state.productsSelected.filter(id => id !== productId)
        ]
      }));
    }
  }

  openProductInEcwid = (productId) => {
    const id = productId;
    const appName = process.env.REACT_APP_APP_ID;
    const productIdJson = JSON.stringify({id: id, ...this.state.filters});
    const appState = encodeURIComponent(`&app_state=${productIdJson}`);
    window.EcwidApp.openPage(`product:mode=edit&id=${id}&return=app:name%3D${appName}${appState}`);
  };

  setDefaultFilterSettings = () => {
    this.setLoadingProducts(true);

    const filters = document
      .getElementById('ecwid-products-list')
      .querySelectorAll('.vertical-filters__dropdowns-list-item.dropdown-filter-panel.products');

    filters.forEach(filter => {
      filter.classList.remove('filled');
    });

    this.setState(() => ({
      filters: {
        keyword: '',
        category: 'all',
        inStock: 'all',
        enabled: 'all',
        page: 1
      }
    }), this.loadGetProducts);
  }

  setFilterCategory(event) {
    const value = event.target.value;

    this._clearFilter(event, value);

    this.setLoadingProducts(true);
    this.setState((state) => ({
      filters: {
        ...state.filters,
        category: value,
        page: 1
      }
    }), this.filterProducts);
  }

  clearFilterCategory(event) {
    event.target.value = 'all';
    this.setFilterCategory(event);
  }

  setFilterEnabled(event) {
    const value = event.target.value;

    this._clearFilter(event, value);

    this.setLoadingProducts(true);
    this.setState((state) => ({
      filters: {
        ...state.filters,
        enabled: value,
        page: 1
      }
    }), this.filterProducts);
  }

  clearFilterEnabled(event) {
    event.target.value = 'all';
    this.setFilterEnabled(event);
  }

  setFilterInStock(event) {
    const value = event.target.value;

    this._clearFilter(event, value);

    this.setLoadingProducts(true);
    this.setState((state) => ({
      filters: {
        ...state.filters,
        inStock: value,
        page: 1
      }
    }), this.filterProducts);
  }

  setFilterIncludedInTheApp(event) {
    const value = event.target.value;

    this._clearFilter(event, value);

    this.setLoadingProducts(true);
    this.setState((state) => ({
      filters: {
        ...state.filters,
        isActive: value,
        page: 1
      }
    }), this.filterProducts);
  }

  clearFilterIncludedInTheApp(event) {
    event.stopPropagation();
    event.target.value = 'all';
    this.setFilterIncludedInTheApp(event);
  }

  clearFilterInStock(event) {
    event.stopPropagation();
    event.target.value = 'all';
    this.setFilterInStock(event);
  }

  setKeyWord(value) {
    this.setLoadingProducts(true);
    this.setState((state) => ({
      filters: {
        ...state.filters,
        keyword: value,
        page: 1
      }
    }), this.filterProducts);
  }

  setPage = (value) => {
    let page = +value;

    const lastPage = Math.ceil(this.state.products.total / this.state.perPage);

    if (Number(page) && page >= 1 && page <= lastPage) {
      this.setState((state) => ({
        filters: {
          ...state.filters,
          page: value
        }
      }));
    } else if (page < 1) {
      this.setState((state) => ({
        filters: {
          ...state.filters,
          page: 1
        }
      }));
    } else if (page > lastPage) {
      this.setState((state) => ({
        filters: {
          ...state.filters,
          page: lastPage
        }
      }));
    }

    this.filterProducts();
    this._pageChanged();
  }

  nextPage = () => {
    this.setPage(+this.state.filters.page + 1);
  }

  prevPage = () => {
    this.setPage(+this.state.filters.page - 1);
  }

  filterProducts = debounce(() => {
    this.loadGetProducts();
  }, 500)

  _clearFilter(event, value) {
    if (value === 'all') {
      event.target.closest('.products').classList.remove('filled');
    } else {
      event.target.closest('.products').classList.add('filled');
    }
  }

  _toggleOpenedFix(event) {
    event.target.closest('.filter-panel-toggle.dropdown-filter-panel').classList.toggle('opened');
  }

  _pageChanged() {
    if (!this.state.pageChanged) {
      this.setState(() => ({
        pageChanged: true
      }));
    }
  }

  render() {
    const {
      categories,
      productsSelected,
      products,
      filters,
      perPage,
      loadingProducts,
      loadingCategories,
      paginateSettings: {
        startProductNumber,
        lastProductNumber
      }
    } = this.state;

    const lastPage = Math.ceil(products.total / perPage);

    return (
      <div className="filtered-list" id="ecwid-products-list">
        <div className="filtered-list__header">
          <div className="filter-panel">
            <div className="filter-panel-toggle dropdown-filter-panel no-body btn-default">
              <div className="dropdown-filter-panel__header">
                <div className="dropdown-filter-panel__header-title"
                     onClick={(event) => this._toggleOpenedFix(event)}>{i18n.t('product_listing.filter')}</div>
                <div className="dropdown-filter-panel__header-icon"
                     onClick={(event) => this._toggleOpenedFix(event)}/>
              </div>
            </div>
            <div className="filter-panel__search">
              <div className="fieldset fieldset--icon fieldset--with-label">
                <div>
                  <div className="fieldset__field-wrapper">
                    <div className="field field--medium">
                      <span className="fieldset__svg-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#9C9C9C" viewBox="0 0 16 16" focusable="false">
                          <path
                            d="M5.8 3.1c1-.9 2.1-1.5 3.6-1.5 1.4 0 2.6.5 3.6 1.5.9 1 1.5 2.1 1.5 3.6 0 1.4-.5 2.6-1.5 3.6-1 .9-2.1 1.5-3.6 1.5-1.3 0-2.6-.5-3.5-1.5-1-1-1.5-2.1-1.5-3.6-.1-1.4.3-2.5 1.4-3.6M.8 16c.2 0 .4-.1.6-.2l3.8-3.7.3.2c1 .7 2.3 1.2 3.8 1.2.9 0 1.8-.2 2.6-.5.8-.4 1.5-.8 2.2-1.5.6-.6 1.1-1.3 1.5-2.1.3-.9.4-1.7.4-2.6 0-.9-.2-1.8-.5-2.6-.4-.8-.8-1.5-1.5-2.2-.6-.6-1.3-1.1-2.1-1.5C11 .2 10.2 0 9.3 0c-.9 0-1.8.2-2.6.5-.8.4-1.5.8-2.2 1.5-.6.6-1.1 1.3-1.4 2.1-.4.9-.5 1.7-.5 2.5 0 1.4.4 2.6 1.2 3.8l.2.3-3.7 3.7c-.2.2-.3.4-.3.7 0 .2.1.4.2.6.3.3.5.3.6.3"/></svg>
                      </span>
                      <label className="field__label">{i18n.t('product_listing.search_placeholder')}</label>
                      <input type="text"
                             className="field__input"
                             value={filters.keyword}
                             onChange={(event) => this.setKeyWord(event.target.value)}
                      />
                      <div className="field__placeholder">{i18n.t('product_listing.search_placeholder')}</div>
                      <span className="field-state--success">
                      <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 26 26"
                           focusable="false">
                        <path
                          d="M5 12l5.02 4.9L21.15 4c.65-.66 1.71-.66 2.36 0 .65.67.65 1.74 0 2.4l-12.3 14.1c-.33.33-.76.5-1.18.5-.43 0-.86-.17-1.18-.5l-6.21-6.1c-.65-.66-.65-1.74 0-2.41.65-.65 1.71-.65 2.36.01z"/></svg></span>
                      <span className="field-state--close">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
                             y="0px"
                             viewBox="0 0 16 16" enableBackground="new 0 0 16 16"
                             xmlSpace="preserve" focusable="false">
                          <path
                            d="M15.6,15.5c-0.53,0.53-1.38,0.53-1.91,0L8.05,9.87L2.31,15.6c-0.53,0.53-1.38,0.53-1.91,0c-0.53-0.53-0.53-1.38,0-1.9l5.65-5.64L0.4,2.4c-0.53-0.53-0.53-1.38,0-1.91c0.53-0.53,1.38-0.53,1.91,0l5.64,5.63l5.74-5.73 c0.53-0.53,1.38-0.53,1.91,0c0.53,0.53,0.53,1.38,0,1.91L9.94,7.94l5.66,5.65C16.12,14.12,16.12,14.97,15.6,15.5z"/></svg>
                      </span>
                    </div>
                    <div className="fieldset__field-prefix"/>
                    <div className="fieldset__field-suffix"/>
                  </div>
                  <div className="field__error" aria-hidden="true" style={{display: 'none'}}/>
                </div>
                <div className="fieldset__note" aria-hidden="true" style={{display: 'none'}}/>
              </div>
            </div>
          </div>
        </div>
        <div className="filtered-list__body">
          <div className="ecwid-orders__filter-panel vertical-filters">
            <div className="vertical-filters__dropdowns-list">
              <div
                className="vertical-filters__dropdowns-list-item dropdown-filter-panel products opened"
                style={{borderTop: '1px solid #c5ccd3'}}
              >
                <div className="dropdown-filter-panel__header" onClick={(event) => this.initCollapses(event)}>
                  <div className="dropdown-filter-panel__header-title">{i18n.t('product_listing.filters.included_in_the_app')}</div>
                  <div className="dropdown-filter-panel__header-icon"/>
                  <div
                    className="dropdown-filter-panel__header-clear"
                    onClick={(event) => this.clearFilterIncludedInTheApp(event)}
                  >
                    {i18n.t('product_listing.filters.clear')}
                  </div>
                </div>
                <div className="dropdown-filter-panel__body dropdown-filter-panel__body--noscroll">
                  <div className="ecwid-orders-filter-popup">
                    <div className="options-list__search" aria-hidden="true" style={{display: 'none'}}/>
                    <ul className="options-list">
                      <li className="options-list__item">
                        <label className="options-list__label">
                          <input className="options-list__radio"
                                 type="radio"
                                 name="IncludedInTheApp"
                                 value="true"
                                 checked={filters.isActive === "true"}
                                 onChange={(event) => this.setFilterIncludedInTheApp(event)}
                          />
                          <span className="radio-btn-icon"/>
                          <span className="options-list__label-text">{i18n.t('product_listing.filters.included_in_the_app_active')}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="vertical-filters__dropdowns-list-item dropdown-filter-panel products opened"
                style={{borderTop: '1px solid #c5ccd3'}}
              >
                <div className="dropdown-filter-panel__header" onClick={(event) => this.initCollapses(event)}>
                  <div className="dropdown-filter-panel__header-title">{i18n.t('product_listing.filters.in_stock_status')}</div>
                  <div className="dropdown-filter-panel__header-icon"/>
                  <div
                    className="dropdown-filter-panel__header-clear"
                    onClick={(event) => this.clearFilterInStock(event)}
                  >
                    {i18n.t('product_listing.filters.clear')}
                  </div>
                </div>
                <div className="dropdown-filter-panel__body dropdown-filter-panel__body--noscroll">
                  <div className="ecwid-orders-filter-popup">
                    <div className="options-list__search" aria-hidden="true" style={{display: 'none'}}/>
                    <ul className="options-list">
                      <li className="options-list__item">
                        <label className="options-list__label">
                          <input className="options-list__radio"
                                 type="radio"
                                 name="inStock"
                                 value="all"
                                 checked={filters.inStock === 'all'}
                                 onChange={(event) => this.setFilterInStock(event)}
                          />
                          <span className="radio-btn-icon"/>
                          <span className="options-list__label-text">{i18n.t('product_listing.filters.in_stock_any')}</span>
                        </label>
                      </li>
                      <li className="options-list__item">
                        <label className="options-list__label">
                          <input className="options-list__radio"
                                 type="radio"
                                 name="inStock"
                                 value="off"
                                 checked={filters.inStock === 'off'}
                                 onChange={(event) => this.setFilterInStock(event)}
                          />
                          <span className="radio-btn-icon"/>
                          <span className="options-list__label-text">{i18n.t('product_listing.filters.in_stock_out')}</span>
                        </label>
                      </li>
                      <li className="options-list__item">
                        <label className="options-list__label">
                          <input className="options-list__radio"
                                 type="radio"
                                 name="inStock"
                                 value="on"
                                 checked={filters.inStock === 'on'}
                                 onChange={(event) => this.setFilterInStock(event)}
                          />
                          <span className="radio-btn-icon"/>
                          <span className="options-list__label-text">{i18n.t('product_listing.filters.in_stock')}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="vertical-filters__dropdowns-list-item dropdown-filter-panel products opened">
                <div className="dropdown-filter-panel__header" onClick={(event) => this.initCollapses(event)}>
                  <div className="dropdown-filter-panel__header-title">{i18n.t('product_listing.filters.availability_status')}</div>
                  <div className="dropdown-filter-panel__header-icon"/>
                  <div
                    className="dropdown-filter-panel__header-clear"
                    onClick={(event) => this.clearFilterEnabled(event)}
                  >
                    {i18n.t('product_listing.filters.clear')}
                  </div>
                </div>
                <div className="dropdown-filter-panel__body dropdown-filter-panel__body--noscroll">
                  <div className="ecwid-orders-filter-popup">
                    <div className="options-list__search" aria-hidden="true" style={{display: 'none'}}/>
                    <ul className="options-list">
                      <li className="options-list__item">
                        <label className="options-list__label">
                          <input className="options-list__radio"
                                 type="radio"
                                 name="enabled"
                                 value="all"
                                 checked={filters.enabled === 'all'}
                                 onChange={(event) => this.setFilterEnabled(event)}
                          />
                          <span className="radio-btn-icon"/>
                          <span className="options-list__label-text">{i18n.t('product_listing.filters.availability_any')}</span>
                        </label>
                      </li>
                      <li className="options-list__item">
                        <label className="options-list__label">
                          <input className="options-list__radio"
                                 type="radio"
                                 name="enabled"
                                 value="off"
                                 checked={filters.enabled === 'off'}
                                 onChange={(event) => this.setFilterEnabled(event)}
                          />
                          <span className="radio-btn-icon"/>
                          <span className="options-list__label-text">{i18n.t('product_listing.filters.availability_disabled')}</span>
                        </label>
                      </li>
                      <li className="options-list__item">
                        <label className="options-list__label">
                          <input className="options-list__radio"
                                 type="radio"
                                 name="enabled"
                                 value="on"
                                 checked={filters.enabled === 'on'}
                                 onChange={(event) => this.setFilterEnabled(event)}
                          />
                          <span className="radio-btn-icon"/>
                          <span className="options-list__label-text">{i18n.t('product_listing.filters.availability_enabled')}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="vertical-filters__dropdowns-list-item dropdown-filter-panel products opened">
                <div className="dropdown-filter-panel__header" onClick={(event) => this.initCollapses(event)}>
                  <div className="dropdown-filter-panel__header-title">{i18n.t('product_listing.filters.categories_status')}</div>
                  <div className="dropdown-filter-panel__header-icon"/>
                  <div
                    className="dropdown-filter-panel__header-clear"
                    onClick={(event) => this.clearFilterCategory(event)}
                  >
                    {i18n.t('product_listing.filters.clear')}
                  </div>
                </div>
                <div className="dropdown-filter-panel__body dropdown-filter-panel__body--noscroll">
                  <div className="ecwid-orders-filter-popup">
                    <div className="options-list__search" aria-hidden="true" style={{display: 'none'}}/>
                    <ul className="options-list">
                      <li className="options-list__item">
                        <label className="options-list__label">
                          <input className="options-list__radio"
                                 type="radio"
                                 name="categories"
                                 value="all"
                                 checked={filters.category === 'all'}
                                 onChange={(event) => this.setFilterCategory(event)}
                          />
                          <span className="radio-btn-icon"/>
                          <span className="options-list__label-text">{i18n.t('product_listing.filters.categories_any')}</span>
                        </label>
                      </li>
                      <li className="options-list__item">
                        <label className="options-list__label">
                          <input className="options-list__radio"
                                 type="radio"
                                 name="categories"
                                 value="0"
                                 checked={filters.category === '0'}
                                 onChange={(event) => this.setFilterCategory(event)}
                          />
                          <span className="radio-btn-icon"/>
                          <span className="options-list__label-text">{i18n.t('product_listing.filters.categories_store_front_page')}</span>
                        </label>
                      </li>
                      {!loadingCategories && categories?.map(category => {
                        return (
                          <li className="options-list__item" key={category.id}>
                            <label className="options-list__label">
                              <input className="options-list__radio"
                                     type="radio"
                                     name="categories"
                                     value={category.id}
                                     checked={+filters.category === category.id}
                                     onChange={(event) => this.setFilterCategory(event)}
                              />
                              <span className="radio-btn-icon"/>
                              <span className="options-list__label-text">{category.fullPath}</span>
                            </label>
                          </li>
                        )
                      })
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filtered-list__items filtered-list__items_height">
            {!loadingProducts && (<div className="d-flex flex-wrap justify-content-between long-list-select-bar" style={{marginBottom: '12px'}}>
              <div className="d-flex flex-wrap wrapper-menu">
                <div className="selected-products">
                  <span style={{margin: '0 6px'}}>{i18n.t('product_listing.product_selected')} <span className="filter-status-counter">{ productsSelected.length }</span></span>
                </div>
                <div
                  className="align-self-center filter-status--handler cursor-pointer"
                  onClick={(event) => this.handleSelectAllProducts(event)}
                >
                  {i18n.t('product_listing.select_all_products_on_page')}
                </div>
                {productsSelected.length > 0 && (<div
                  className="align-self-center filter-status--handler cursor-pointer"
                  onClick={(event) => this.handleClearAllProducts(event)}
                >
                    {i18n.t('product_listing.reset')}
                </div>
                )}
              </div>
              {productsSelected.length > 0 && (<div
                className="btn-group btn-group--filter dropdown-toggle mb-0"
                onClick={(event) => {
                  event.target.closest('.dropdown-toggle').classList.toggle('opened')
                }}
              >
                <button type="button"
                        className="btn btn-default btn-small"
                        aria-hidden="true"
                        style={{display: 'none'}}/>
                <div className="btn btn-default btn-dropdown list-dropdown-no-general-text btn-small mr-0">
                  <div className="iconable-link" role="link" tabIndex="0">
                    <span className="iconable-link__text">
                      <span className="">{i18n.t('product_listing.mass_update')}</span>
                    </span>
                    <span className="iconable-link__icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 28" focusable="false">
                        <path d="M3.3 9.5l5.6 5.1 6-5.1c.8-.7 1.9-.7 2.6 0 .8.7.8 1.8 0 2.5l-7.2 6.4c-.5.4-1 .6-1.4.6s-1-.2-1.3-.5L.7 12.1c-.8-.7-.8-1.8 0-2.5.6-.8 1.9-.8 2.6-.1z"/>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="list-dropdown list-dropdown-medium list-dropdown--left list-dropdown--left--border-none">
                  <div className="list-dropdown__content">
                    <ul className="list-dropdown__item--custom">
                      <li
                        className="list-dropdown__item"
                        onClick={() => this.setStatusesProducts(true)}
                      >
                        {i18n.t('product_listing.enable_selected')}
                      </li>
                      <li
                        className="list-dropdown__item"
                        onClick={() => this.setStatusesProducts(false)}
                      >
                        {i18n.t('product_listing.disable_selected')}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              )}
            </div>
            )}
            <div className="long-list">
              {loadingProducts && new Array(products.count).fill(1).map((item, index) => (
                <div className="placeholder-titles" key={item + index}>
                  <div className="placeholder-product placeholder-blink--delay2x">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <div className="placeholder-blink placeholder-image"/>
                        <div className="d-flex flex-column justify-content-between">
                          <div className="placeholder-blink placeholder-product-title"/>
                          <div className="placeholder-blink placeholder-description"/>
                        </div>
                      </div>
                      <div className="placeholder-blink placeholder-checkbox"/>
                    </div>
                  </div>
                </div>
              ))}
              {!loadingProducts && products?.items?.map(product => {
                return (
                  <ProductItem
                    onSetProductStatus={this.setProductStatus}
                    onSetChecked={this.onSetChecked}
                    openProductInEcwid={this.openProductInEcwid}
                    handleSetCheckedSelect={this.handleSetCheckedSelect}
                    title={product.name}
                    productId={product.id}
                    productSku={product.sku}
                    imageUrl={product.imageUrl}
                    status={product.status}
                    enabled={product.enabled}
                    quantity={product.quantity}
                    isChecked={productsSelected.includes(product.id)}
                    key={product.id}
                  />
                )
              })}
              {!loadingProducts && products.count === 0 && (
                <EmptyProductsList setDefaultFilterSetting={this.setDefaultFilterSettings}/>
              )}
            </div>
            {loadingProducts && (
              <div className="placeholder-titles">
                <div className="placeholder-pagination placeholder-blink--delay2x">
                  <div className="d-flex justify-content-end">
                    <div className="placeholder-blink placeholder-showing-status__pages"/>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="placeholder-blink placeholder-nav__pages"/>
                  </div>
                </div>
              </div>
            )}
            {(!loadingProducts && products.count > 0) && <div className="pagination mb-0" id="pagination">
              <div className="pagination__status showing-status">
                {i18n.t('product_listing.filters.pages.showing')} <span className="showing-status__pages">{startProductNumber} - {lastProductNumber}</span> {i18n.t('product_listing.filters.pages.of')} <span
                className="showing-status__total">{products.total}</span> {i18n.t('product_listing.filters.pages.items')}.
              </div>
              <div>
                <button className="btn btn-default btn-medium pagination__nav pagination__nav--prev"
                        onClick={this.prevPage}
                        disabled={filters.page <= 1}
                >
                  <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" focusable="false">
                      <path fill="#010101"
                            d="M18.5 3.85l-8.9 9.02 8.9 9.27c.66.65.66 1.71 0 2.36-.67.65-1.74.65-2.4 0L6 14.06c-.33-.33-.5-.76-.5-1.18 0-.43.17-.86.5-1.18L16.1 1.49c.66-.65 1.74-.65 2.41 0 .66.65.66 1.71-.01 2.36z"/>
                    </svg>
                  </span>
                </button>
                <span className="pagination__label">{i18n.t('product_listing.filters.pages.page')}:
                <span className="pagination__current">
                  <input type="text"
                         className="form-control input-medium"
                         maxLength="7"
                         value={filters.page}
                         onChange={(event) => this.setPage(event.target.value)}
                  />
                  <span className="form-control input-medium input-size"> {filters.page} </span>
                </span>
                of <span className="pagination__total"/>{lastPage}</span>
                <button className="btn btn-default btn-medium pagination__nav pagination__nav--next"
                        onClick={this.nextPage}
                        disabled={filters.page >= lastPage}
                >
                  <span className="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" focusable="false">
                      <path fill="#010101"
                            d="M7.5 22.15l8.9-9.02-8.9-9.28c-.66-.65-.66-1.71 0-2.36.67-.65 1.74-.65 2.4 0L20 11.94c.33.33.5.76.5 1.18 0 .43-.17.86-.5 1.18L9.9 24.51c-.66.65-1.74.65-2.41 0-.66-.65-.66-1.71.01-2.36z"/>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

FilterList.contextType = StoreContext;